import { LineChart, Text } from "@tremor/react";
import moment from "moment";

const timeOfDayRange = (time) => {
    switch (true) {
        case (time <= '11'):
            return 'Morning'

        case (time <= '16'):
            return 'Afternoon'

        case (time <= '20'):
            return 'Evening'
        default:
            return 'Night'
    }
}

const weekOfMonthRange = (day) => {
    switch (true) {
        case (day <= '6'):
            return 'Week 1'

        case (day <= '13'):
            return 'Week 2'

        case (day <= '20'):
            return 'Week 3'

        case (day <= '27'):
            return 'Week 4'

        case (day <= '31'):
            return 'Week 5'
        default:
            return 'Night'
    }
}

const formatDataForDay = (data) => {
    const t = data?.map(item => ({ name: timeOfDayRange(moment(item?.createdAt).format('H')), amount: parseFloat(item?.amount) }))

    return [
        {
            name: 'Morning',
            value: t?.filter(item => item?.name === 'Morning').length
        },
        {
            name: 'Afternoon',
            value: t?.filter(item => item?.name === 'Afternoon').length
        },
        {
            name: 'Evening',
            value: t?.filter(item => item?.name === 'Evening').length
        },
        {
            name: 'Night',
            value: t?.filter(item => item?.name === 'Night').length
        }
    ]
}

const formatDataForMonth = (data) => {
    const t = data?.map(item => ({ name: weekOfMonthRange(moment(item?.createdAt).format('D')), amount: parseFloat(item?.amount) }))

    return [
        {
            name: 'Week 1',
            value: t?.filter(item => item?.name === 'Week 1').length
        },
        {
            name: 'Week 2',
            value: t?.filter(item => item?.name === 'Week 2').length
        },
        {
            name: 'Week 3',
            value: t?.filter(item => item?.name === 'Week 3').length
        },
        {
            name: 'Week 4',
            value: t?.filter(item => item?.name === 'Week 4').length
        },
        {
            name: 'Week 5',
            value: t?.filter(item => item?.name === 'Week 5').length
        },
    ]
}

const formatDataForWeek = (data) => {
    const t = data?.map(item => ({ name: moment(item?.createdAt).format('dddd'), amount: parseFloat(item?.amount) }))

    return [
        {
            name: 'Sunday',
            value: t?.filter(item => item?.name === 'Sunday').length
        },
        {
            name: 'Monday',
            value: t?.filter(item => item?.name === 'Monday').length
        },
        {
            name: 'Tuesday',
            value: t?.filter(item => item?.name === 'Tuesday').length
        },
        {
            name: 'Wednesday',
            value: t?.filter(item => item?.name === 'Wednesday').length
        },
        {
            name: 'Thursday',
            value: t?.filter(item => item?.name === 'Thursday').length
        },
        {
            name: 'Friday',
            value: t?.filter(item => item?.name === 'Friday').length
        },
        {
            name: 'Saturday',
            value: t?.filter(item => item?.name === 'Saturday').length
        }
    ]
}

const dataFormatter = (number) =>
    `${Intl.NumberFormat("us").format(number).toString()}`;

const TremorLinechart = (props) => {
    const data = props.data?.filter(a => a?.status === 'successful'),
        segment = props.segment

    return (
        <div>
            <Text>Volume of transactions</Text>
            <LineChart
                data={{ 'Today': formatDataForDay(data), 'This week': formatDataForWeek(data), 'This month': formatDataForMonth(data) }[segment]}
                dataKey="name"
                showLegend={false}
                categories={["value"]}
                colors={["blue"]}
                valueFormatter={dataFormatter}
                marginTop="mt-6"
                yAxisWidth="w-10"
                height="h-56"
            />
        </div>
    )
}

export default TremorLinechart;