import { Card, Result } from "antd";
import React from "react";


export default function NotFoundCard(props) {

    return (
        <div>
            <Card
            // title="No Merchant"
                className="mx-auto"
                style={{
                    maxWidth: '500px',
                    textAlign: "center"
                }}
            >
                <h6>{props?.title}</h6>

                <Result
                    status="404"
                    title="404"
                    subTitle={props?.subTitle}
                />

            </Card>
        </div>
    )
}