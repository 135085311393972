import { Card, Divider, Button, Tag } from "antd";
import { DownloadOutlined } from '@ant-design/icons'
import moment from "moment";
import React from "react";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useState } from "react";

export default function Receipt(props) {

    const details = props?.data
    const [isloading, setIsloading] = useState(false)

    const printPDF = () => {
        setIsloading(true);
        const input = document.getElementById('qrTemplate')

        html2canvas(input, { allowTaint: true, useCORS: true }).then((canvas) => {
            const imgWidth = 293;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            const imgData = canvas.toDataURL('img/png');
            const pdf = new jsPDF('p', 'mm', 'a3');
            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

            setIsloading(false);
            pdf.save(`${details?.transactionId}-receipt.pdf`);
        });
    };

    return (
        <div>
            <Card
                id="qrTemplate"
                className="mx-auto"
                style={{
                    maxWidth: '500px',
                    textAlign: "left"
                }}
            >
                <h6>{details?.Merchant?.name}</h6>
                <p className="text-muted">#{details?.transactionId}</p>
                <img
                    src={require("../../images/logo.png")}
                    alt=""
                    width={40}
                    style={{
                        position: 'absolute',
                        top: 20,
                        right: 20
                    }}
                />
                <p >{moment(details?.createdAt).format("MMMM DD YYYY @ hh:mm:ss a")}</p>
                <table
                    style={{
                        width: '100%'
                    }}
                >
                    <tr>
                        <td className="text-muted">Reference</td>
                        <td style={{ textAlign: 'right' }}>{details?.reference}</td>
                    </tr>
                    <Divider />
                    <tr>
                        <td className="text-muted">Customer Number</td>
                        <td style={{ textAlign: 'right' }}>{details?.customerNumber}</td>
                    </tr>
                    <Divider />
                    <tr>
                        <td className="text-muted">Status</td>
                        <td style={{ textAlign: 'right' }}>
                            <Tag color={details?.status === "pending" ? "geekblue" : details?.status === "failed" ? "red" : "green"}
                            ><b>{details?.status?.toUpperCase()}</b></Tag>
                        </td>
                    </tr>
                </table>

                <Divider />
                <table
                    style={{
                        width: '100%'
                    }}
                >
                    <tr>
                        <td>Qty</td>
                        <td>Description</td>
                        <td style={{ textAlign: 'right' }}>Amount</td>
                    </tr>
                    {/* <Divider style={{width: '100%'}}/> */}
                    <tr>
                        <td>x1</td>
                        <td>UMB BANK</td>
                        <td className="mt-5" style={{ textAlign: 'right' }}>{details?.amount}</td>
                    </tr>
                    {/* <Divider /> */}
                    <hr />
                    <tr>
                        <td>Subtotal</td>
                        <td></td>
                        <td style={{ textAlign: 'right' }}>{details?.amount}</td>
                    </tr>
                    <tr>
                        <td><h5>Total</h5></td>
                        <td></td>
                        <td style={{ textAlign: 'right' }}><h5>{details?.amount}</h5></td>
                    </tr>

                </table>

                <Button
                    onClick={() => {
                        printPDF()
                    }}
                    loading={isloading}
                    type="text"
                    style={{
                        color: 'black',
                        margin: '40px auto 0',
                        display: 'block'
                    }}> <DownloadOutlined /> Download</Button>

                <Button
                    type="text"
                    style={{
                        color: 'blue',
                        margin: '40px auto 0',
                        display: 'block'
                    }}>Report an issue</Button>
            </Card>
        </div>
    )
}