import { Card, Avatar, Row, Col, Tag, Typography, Empty, Spin } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import { protectedResources } from "../../../../authConfig";
import listTransactions from "../../../../api/listTransactions";
import moment from "moment";
import React, { useState, useEffect } from "react";
import TransactionDetails from "../../Transactions/TransactionDetails";
import axios from "axios";

const { Text } = Typography;

export default function TableView(props) {

    const { instance, accounts, inProgress } = useMsal();
    const [modalVisible, setModalVisible] = useState(false)
    const [transactionInfo, setTransactionInfo] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState(null)

    function getChannelImage(channelId) {
        switch (channelId) {
            case 1:
                return require('../../../../images/MicrosoftTeams-image (13).png')
            case 2:
                return require('../../../../images/MicrosoftTeams-image (15).png')
            case 3:
                return require('../../../../images/MicrosoftTeams-image (14).png')
            case 4:
                return require('../../../../images/MicrosoftTeams-image (12).png')
            default:
                break;
        }

    }

    useEffect(() => {
        // props.merchantId ?
            // getTransactionByMerchantId(417) 

            if(props.merchantId) {
                getTransactionByMerchantId(props.merchantId)
              }else {
                handleListTransactionsAPI()
              }

            // handleListTransactionsAPI()
        // const d = [
        //     {
        //         "id": 68,
        //         "amount": "0.01",
        //         "customerNumber": "0557587124",
        //         "reference": "test",
        //         "status": "successful",
        //         "momoReference": "6e8fa57790a94d32b509573d8",
        //         "transactionId": "45bd916b06a8408491484a69b0f40347",
        //         "createdAt": "2022-09-22T13:41:42.000Z",
        //         "updatedAt": "2022-09-22T13:41:55.000Z",
        //         "MerchantId": 1,
        //         "UserId": 2,
        //         "CollectionChannelId": 1
        //     },
        //     {
        //         "id": 69,
        //         "amount": "0.01",
        //         "customerNumber": "0501400337",
        //         "reference": "test",
        //         "status": "successful",
        //         "momoReference": "af6540f2f7d4407ca35723a2f",
        //         "transactionId": "2c638ba51a4443d5916d9bd6a1385900",
        //         "createdAt": "2022-09-23T10:05:30.000Z",
        //         "updatedAt": "2022-09-23T10:05:48.000Z",
        //         "MerchantId": 1,
        //         "UserId": 10,
        //         "CollectionChannelId": 3
        //     },
        //     {
        //         "id": 70,
        //         "amount": "0.01",
        //         "customerNumber": "0273181588",
        //         "reference": "invoice number 30",
        //         "status": "failed",
        //         "momoReference": "2cca311eec8a4ca3ad22b1c27",
        //         "transactionId": "b547b6a651da4ce6b39103cc325845b6",
        //         "createdAt": "2022-09-23T10:48:19.000Z",
        //         "updatedAt": "2022-09-23T10:48:27.000Z",
        //         "MerchantId": 1,
        //         "UserId": 4,
        //         "CollectionChannelId": 3
        //     }
        // ]

        // setData([])

        // setData(filterData(d.sort(function (a, b) {
        //     return new Date(b.updatedAt) - new Date(a.updatedAt);
        // }), props?.status))

    }, [props?.refresh, props?.startDate, props?.status])

    const handleListTransactionsAPI = () => {
        setIsLoading(true)

        instance.acquireTokenSilent({
            scopes: protectedResources.basicAccess.scopes,
            account: accounts[0]
        }).then(response => {
            //get accessToken
            // console.log(response.accessToken
            // loadRequests(response.accessToken)
            listTransactions(response.accessToken, props?.startDate, props?.endDate).then(result => {

                // console.log(result);
                setIsLoading(false)
                setData(filterData(result?.data.sort(function (a, b) {
                    return new Date(b?.createdAt) - new Date(a?.createdAt);
                }), props?.status))
            }).catch(error => {
                console.log(error)
                setIsLoading(false)
            })
        }).catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
                if (accounts[0] && inProgress === InteractionStatus.None) {
                    instance.acquireTokenPopup({
                        scopes: protectedResources.basicAccess.scopes,
                    }).then(response => {
                        listTransactions(response.accessToken, props?.startDate, props?.endDate).then(result => {
                            // console.log(result);
                            setIsLoading(false)
                            setData(filterData(result?.data.sort(function (a, b) {
                                return new Date(b?.createdAt) - new Date(a?.createdAt);
                            }), props?.status))
                        }).catch(error => {
                            console.log(error);
                            setIsLoading(false)

                        })

                    }).catch(error => {
                        setIsLoading(false)
                        console.log(error)

                    });
                }
            }
        })
    }

    const filterData = (data, status) => {
        if (status !== 'all') {
            return data.filter(value => value.status === status)
        } else {
            return data
        }

    }

    const getTransactionByMerchantId = (merchantId) => {
        setIsLoading(true)

        instance.acquireTokenSilent({
            scopes: protectedResources.basicAccess.scopes,
            account: accounts[0]
        }).then(async (response) => {
            //get accessToken
            // console.log(response.accessToken
            try {
                
              const transactionsResponse = await axios({
                    method: 'get',
                    url: process.env.REACT_APP_API_URL + '/api/transactions/merchantId/' + merchantId,
                    headers: {
                        'Authorization': 'Bearer ' + response.accessToken
                    }
                })
                // console.log(transactionsResponse.data);

                setData(filterData(transactionsResponse.data?.data?.sort(function (a, b) {
                    return new Date(b?.createdAt) - new Date(a?.createdAt);
                }), props?.status))
                
            } catch (error) {
                setIsLoading(false)
                console.log(error.message);
            }
            
        }).catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
                if (accounts[0] && inProgress === InteractionStatus.None) {
                    instance.acquireTokenPopup({
                        scopes: protectedResources.basicAccess.scopes,
                    }).then(async(response) => {
                       
                        try {
                
                            const transactionsResponse = await axios({
                                  method: 'get',
                                  url: process.env.REACT_APP_API_URL + '/api/transactions/merchantId/' + merchantId,
                                  headers: {
                                      'Authorization': 'Bearer ' + response.accessToken
                                  }
                              })
              
                              setData(filterData(transactionsResponse.data?.data?.sort(function (a, b) {
                                  return new Date(b?.createdAt) - new Date(a?.createdAt);
                              }), props?.status))
                              
                          } catch (error) {
                              setIsLoading(false)
                              console.log(error.message);
                          }
                          

                    }).catch(error => {
                        setIsLoading(false)
                        console.log(error)

                    });
                }
            }
        })
    }

    const tableviewCell = (value, key) => {
        // console.log(value)
        return (
            <Card
                key={key}
                onClick={() => { setModalVisible(true); setTransactionInfo(value) }}
                hoverable={false}
                style={{ borderRadius: '10px', marginBottom: '20px', height: '170px' }} >
                <p className="text-muted" style={{ position: 'absolute', top: 10 }}>#{value?.transactionId}</p>
                <p
                    className="text-muted"
                    style={{ position: 'absolute', right: 20, bottom: 10 }}
                >{moment(value?.createdAt).format("hh:mm a (MMM DD)")}</p>
                <Row gutter={10} className="mt-4">
                    <Col flex={0.5}>
                        <img src={getChannelImage(value?.CollectionChannelId)} height={40} width={40} style={{ objectFit: 'cover', borderRadius: '100%' }} alt="" />
                    </Col>

                    <Col flex={10}>
                        <p style={{ margin: '0 0 5px 0' }}>{value?.customerNumber}</p>
                        <h6 style={{ margin: '0' }}>GHS {value?.amount}</h6>
                    </Col>


                    <Col flex={1}>
                        <Tag
                            style={{ margin: '10px -30px 0 0' }}
                            color={value?.status === "pending" ? "geekblue" : value?.status === "failed" ? "red" : "green"}>{value?.status}</Tag>
                    </Col>
                </Row>
                <hr />
                <Text type="secondary" style={{ margin: 0 }}>{value?.reference}</Text>
            </Card>
        )
    }


    return (
        <div
            style={{ height: props?.height, padding: '2%' }}
        >
            <TransactionDetails
                transactionInfo={transactionInfo}
                modalVisible={modalVisible}
                onCancel={() => setModalVisible(false)}
            />
            {isLoading ? <Spin style={{display: 'block'}}/> : data !== null ?
                data?.slice(props?.minValue, props?.maxValue)?.map((value, index) => {
                    return tableviewCell(value, index)
                }) : <Empty />
            }

        </div>
    )
}