import axios from 'axios'


export default async function startCollectionNoAuth(merchantId,
    collectionChannelId,
    amount,
    customerNumber,
    reference,
    transactionFee,
    elevyAmount,
    clientReference
) {

    var data = JSON.stringify({
        "merchantId": merchantId,
        "collectionChannelId": collectionChannelId,
        "amount": amount,
        "customerNumber": customerNumber,
        "reference": reference,
        "transactionFee": transactionFee,
        "elevyAmount": elevyAmount,
        "clientReference": clientReference,
    });

    var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/openapi/qrcollectionswithcharges`,
        headers: {
            'Content-Type': 'application/json',
            'x-client-baseurl': `${process.env.REACT_APP_X_CLIENT_BASE_URL}`,
        },
        data: data
    };

    try {

        const response = await axios(config);
        console.log(response.data);
        return response.data

    } catch (error) {
        console.log(error)
    }

}