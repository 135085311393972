import { Form, Input, Button, Divider, Row, Col } from 'antd'
import { useForm } from 'antd/es/form/Form';
import React, { useState } from "react";
import ConfirmationModal from './ConfirmationModal';
import PaymentOptionCard from './PaymentOptionCard';
import axios from 'axios';


export default function CheckoutForm(props) {

    const [selected, setSelected] = useState({});
    const [modalVisible, setModalVisible] = useState(false);
    const [customerData, setCustomerData] = useState({});
    const [isDisabled, setisDisabled] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [feesInformation, setFeesInformation] = useState({
        clientReference: '',
        transactionFee: 0.0,
        elevyAmount: 0.0
    })

    const [form] = Form.useForm();
    const paymentOptions = [
        {
            key: '1',
            title: 'MTN Mobile Money',
            image: require('../../images/MicrosoftTeams-image (13).png'),
            alt: 'MTN Mobile Money'
        },
        {
            key: '3',
            title: 'Vodafone Cash',
            image: require('../../images/MicrosoftTeams-image (14).png'),
            alt: 'vf_cash'
        },
        {
            key: '2',
            title: 'Airtel Money',
            image: require('../../images/MicrosoftTeams-image (15).png'),
            alt: 'airtel_money'
        }
    ]

    return (
        <div>
            <Form
                form={form}
                name="basic"
                labelCol={{
                    span: 12,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={(values) => {
                    // console.log(values);
                    setCustomerData(values)
                    // form.preventDefault()
                    // setModalVisible(true)
                    setIsLoading(true)

                    const channelValue = { '1': 'mtn-gh', '3': 'vodafone-gh', '2': 'tigo-gh' }[selected.key]

                    try {

                        axios({
                            method: 'post',
                            url: `${process.env.REACT_APP_API_URL}/openapi/qrcharges`,
                            data: {
                                qrId: props?.merchantId,
                                channel: channelValue,
                                customerMsisdn: values.number,
                                amount: values.amount
                            }
                        }).then(value => {

                            // console.log(value.data);

                            if (value.data.status) {
                                setIsLoading(false)

                                setFeesInformation({
                                    ...feesInformation,
                                    'clientReference': value.data.data?.clientReference,
                                    'transactionFee': value.data.data?.transactionFee,
                                    'elevyAmount': value.data.data?.elevyAmount
                                })

                                setModalVisible(true)
                            } else {
                                setIsLoading(false)
                            }

                        }).catch(error => {
                            console.log(error.message);
                            setIsLoading(false)
                        })

                    } catch (error) {
                        setModalVisible(false)
                        setIsLoading(false)
                    }


                }}
                //   onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout='vertical'
            >
                <Form.Item
                    style={{
                        textAlign: 'right'
                    }}
                    label="Amount"
                    name="amount"
                    rules={[
                        {
                            required: true,
                            message: 'Please input amount!',
                        },
                    ]}
                >
                    <Input
                        type='number'
                        style={{
                            backgroundColor: "#f8f9fa",
                            border: '#f8f9fa',
                            height: '35px',
                            width: '100%',
                            overflow: 'hidden'
                        }}
                    />
                </Form.Item>

                <Form.Item
                    style={{
                        textAlign: 'right'
                    }}
                    label="Phone Number"
                    name="number"
                    rules={[
                        {
                            required: true,
                            message: 'Please input phone number!',
                        },
                    ]}
                >
                    <Input
                        type='number'
                        style={{
                            backgroundColor: "#f8f9fa",
                            border: '#f8f9fa',
                            height: '35px',
                            width: '100%',
                            overflow: 'hidden'
                        }}
                    />
                </Form.Item>

                <Form.Item
                    style={{
                        textAlign: 'right'
                    }}
                    label="Reference"
                    name="reference"
                    rules={[
                        {
                            required: true,
                            message: 'Please input reference!',
                        },
                    ]}
                >
                    <Input
                        style={{
                            backgroundColor: "#f8f9fa",
                            border: '#f8f9fa',
                            height: '35px',
                            width: '100%',
                            overflow: 'hidden'
                        }}
                    />
                </Form.Item>

                <Divider plain>Payment Option</Divider>
                <div>
                    <Row gutter={5} align={"left"}>

                        {paymentOptions.map(option => {
                            return (<Col>
                                <PaymentOptionCard
                                    title={option.title}
                                    image={option.image}
                                    alt={option.alt}
                                    selected={selected.title === option.title}
                                    onClick={() => {
                                        setisDisabled(false)
                                        setSelected(option)
                                    }}
                                />
                            </Col>)
                        })}

                    </Row>
                </div>



                <Form.Item>
                    <Button
                        loading={isLoading}
                        disabled={isDisabled}
                        style={{

                            width: '100%',
                            height: '45px',
                            marginTop: '10%',
                            backgroundColor: !isDisabled ? "#FCB426" : "#f8f9fa",
                            border: '#FCB426',
                            color: 'black'

                        }}
                        type="primary"
                        htmlType="submit"
                    // onClick={()=> {
                    // form.validateFields().then( values => {
                    //     console.log(values)
                    // })
                    // setModalVisible(true)
                    // }}
                    >
                        Pay
                    </Button>
                </Form.Item>
            </Form>
            <ConfirmationModal
                data={props?.data}
                merchantId={props?.merchantId}
                selectedPaymentOption={selected}
                feesInformation={feesInformation}
                customerData={customerData}
                modalVisible={modalVisible}
                onCancel={() => setModalVisible(false)}
                source={"client"}
            />
        </div>
    )
}