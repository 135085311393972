import axios from 'axios'

export default async function checkStatusNoAuth(transactionId) {


    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/openapi/transactions/${transactionId}/status`,
      headers: {
        'Content-Type': 'application/json'
    }
    };
    
    try {
        const response = await axios(config);
        return response.data

    } catch (error) {
        console.log(error)
    }

}