import { Text, Metric, Title, DonutChart, Legend } from '@tremor/react';
import { numberWithCommas } from '../../../../Helper';



const statusFilter = (data) => {

    const s = data?.filter(a => a.status === 'successful')
    const p = data?.filter(a => a.status === 'pending')
    const f = data?.filter(a => a.status === 'failed')

    return [
        {
            name: 'Successful',
            value: s?.length
        },
        {
            name: 'Failed',
            value: f?.length
        },
        {
            name: 'Pending',
            value: p?.length
        }
    ]

}


const TremorPiechart = (props) => (
    <div>
        <Text> Total transactions </Text>
        <Metric> {numberWithCommas(props?.data?.length)} </Metric>
        <DonutChart
            data={statusFilter(props?.data)}
            category="value"
            variant='donut'
            dataKey="name"
            // showLabel={true}
            // valueFormatter={valueFormatter}
            colors={["emerald", "rose", "sky"]}
        />

        <div style={{
            position: 'absolute'
        }}>


            <Legend
                categories={["Successful", "Failed", "Pending"]}
                colors={["emerald", "red", "sky"]}
            />

        </div>
    </div>
);

export default TremorPiechart;