import { Button, Card, Modal } from "antd";
import {PrinterOutlined} from '@ant-design/icons'
import React, {useState, useRef, useEffect} from "react";
import { QRCodeSVG } from "qrcode.react";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import pdfString from "../../../services/pdfString";

export default function QrModal(props) {

    const collectionMethods = [require("../../../images/MicrosoftTeams-image (15).png"), require("../../../images/MicrosoftTeams-image (14).png"), require("../../../images/MicrosoftTeams-image (13).png"), require("../../../images/MicrosoftTeams-image (12).png")]
    const [url, setUrl] = useState("");
    const qrRef = useRef();
    const [isloading, setIsloading] = useState(false)


    useEffect(() => {
        setUrl(`${process.env.REACT_APP_X_CLIENT_BASE_URL}/client/${props?.data?.merchant?.qrId}`)
    }, [props?.data])

    const printPDF = () => {
        setIsloading(true);
        const input = document.getElementById('qrTemplate') 
    
        html2canvas(input, { allowTaint: true, useCORS: true }).then((canvas) => {
          const imgWidth = 293;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const imgData = canvas.toDataURL('img/png');
          const pdf = new jsPDF('p', 'mm', 'a3');
          pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    
          setIsloading(false);
          pdf.save(`${props?.data?.merchant?.name}-qrcode.pdf`);
        });
      };

    const qrcode = (
        <QRCodeSVG
          id="qrCode"
          value={url}
          size={200}
          bgColor={"#fff"}
          level={"H"}
        />
    );

    return (
        <div>
            <Modal
                // centered
                visible={props?.showModal}
                onCancel={props?.onCancel}
                footer={null}
                title="QR"
                destroyOnClose={true}
            >

                <Card
                id="qrTemplate"
                    style={{
                        height: '100%'
                    }}
                >

                    <img src={require('../../../images/speedpay.png')} alt='' width={300}
                        style={{ display: 'block', margin: 'auto' }}
                    />
                    {/* <img className="mt-3" style={{ display: 'block', margin: 'auto' }} width={250} height={250} alt="" /> */}

                    <div className="mt-2 mb-2" style={{textAlign: 'center'}}>
                    <h4 className="d-inline">Simply Dial</h4> <h2 style={{color: '#FCB426'}} className="d-inline">{props?.data?.merchant?.ussd?.toUpperCase()}</h2>
                    </div>

                    <div
                        style={{ display: 'flex',alignItems: 'center', justifyContent: 'center',  margin: 'auto' ,marginTop: '50px', border: '#F4B223 solid 5px', width: '250px', padding: '20px', borderRadius: '16px', position: 'relative', zIndex: '50'}}>
                        <div
                            style={{ width: '150%', backgroundColor: 'rgb(255, 255, 255)', marginLeft: '-13%', height: '80%', marginTop: '1%', position: 'absolute', zIndex: '-5' }}>
                            <p style={{ visibility: 'collapse' }}>a</p>
                        </div>
                        <div
                            style={{ width: '115%', backgroundColor: 'rgb(255, 255, 255)', marginLeft: '0%', marginTop: '2%', height: '75%', position: 'absolute', zIndex: '-4', transform: 'rotate(90deg)'}}>
                            <p style={{ transform: 'rotate(-90deg)', left: '-14%', bottom: '35%', position: 'absolute' }}>SCAN TO PAY</p>
                        </div>

                    {/* QR CODE DIV */}
                    <div style={{
                        textAlign: 'center',
                        zIndex: 50
                    }} className="mx-auto mt-3" ref={qrRef}>{qrcode}</div>

                    {/* QR CODE DIV */}
                    </div>
                    
                    <div
                        style={{
                            textAlign: 'center',
                            marginTop: '25px'
                        }}
                    >
                        {
                            collectionMethods.map(item => {
                                return <img
                                    src={item}
                                    alt=""
                                    width={80}
                                    height={80}
                                    style={{
                                        borderRadius: '100%',
                                        // border: 'solid black',
                                        objectFit: 'fill',
                                        margin: '2%',
                                        padding: '5px'
                                    }} />
                            }
                            )

                        }
                    </div>
                            
                    <p style={{textAlign: 'center', marginTop: '10px'}}>PAY TO</p>

                    <div
                        style={{
                            backgroundColor: '#FCEBCA',
                            textAlign: 'center',
                            marginLeft: '-10px',
                            marginRight: '-10px',
                            padding: '0.5%',
                            paddingTop: '2%',
                        }}
                    >
                        <h6>{props?.data?.merchant?.name?.toUpperCase()}</h6>
                    </div>
                
                    <div
                        style={{
                            textAlign: 'center',
                            marginTop: '30px',
                            padding: '0.5%'
                        }}
                    >
                        <img src={require("../../../images/logo.png")} alt="" width={70}/>
                    </div>

                </Card>
                <Button 
                loading={isloading}
                onClick={() => {
                    printPDF()
                }}
                style={{display: 'block'}}
                className="mx-auto" 
                icon={<PrinterOutlined />}
                type="text"></Button>
            </Modal>
        </div>
    )
}