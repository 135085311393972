import axios from "axios";

export default async function getMerchant(token) {

    var config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/user/merchant`,
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };

    try {
        const response = await axios(config)
        return response.data
    } catch (error) {
        console.log(error);
    }

}