import axios from 'axios'


export default async function listTransactions(token, startDate, endDate) {

    try {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/api/transactions?fromDate=${startDate}&toDate=${endDate}`,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }

        const response = await axios(config)
        // console.log(response.data);
        return response.data

    } catch (error) {
        console.log(error);
    }

}