import React from "react";


export default function Banner() {


    return(
        <div 
        style={{
            margin: '40px'
        }}>
            {/* <h4>Speed Pay</h4> */}
            <img src={require("../../images/speedpay.png")} alt="" width={200}/>
        </div>
    )
}