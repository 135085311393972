import { Layout, Card, Tag, Divider, Table, Button, Result, Spin } from "antd";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Banner from "../components/client/Banner";
import Footer from "../components/client/Footer";
import Receipt from "../components/result/Receipt";
import { useParams } from "react-router-dom";
import getTransationByRefNoAuth from "../api/getTransationByRefNoAuth";
import NotFoundCard from "../components/result/NotFoundCard";
const { Content } = Layout

export default function ReceiptPage() {

    let { receiptId } = useParams();
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState(null)

    useEffect(() => {
        setIsLoading(true)
        getTransationByRefNoAuth(receiptId).then(result => {
            if (result.status) {
                setData(result?.data)
                
            }
            console.log(result)
            setIsLoading(false)
        }).catch(error => {
            console.log(error);
            setIsLoading(false)
        })
    }, [])

    return (
        <div>
            <Layout
                className="layout"
                style={{
                    minHeight: '100vh',
                    width: '100%',
                    overflow: 'hidden'
                }}
            >
                <Content>
                    <Container>
                        <Row>
                            <Col style={{ textAlign: "Center" }}>
                                <Banner />
                                {!isLoading ? data !== null ? <Receipt data={data} /> : <NotFoundCard  title="Receipt not found" subTitle={`Receipt with id ${receiptId} does not exist`}/> : <Spin />}
                                <Footer />
                            </Col>
                        </Row>
                    </Container>
                </Content>
            </Layout>

        </div>
    )
}