import { Routes, Route, Navigate } from "react-router-dom";
import CheckoutPage from './pages/CheckoutPage';
import { useEffect } from "react";
import './App.css';
import ReactGA from "react-ga";
import AuthenticatedRoute from "./AuthenticatedRoute.js";
import NotFoundPage from "./pages/NotFoundPage.jsx";
import ReceiptPage from "./pages/ReceiptPage.jsx";
import { ConfigProvider } from "antd";

const TRACKING_ID = "G-Z3CV82P2MW";
ReactGA.initialize(TRACKING_ID);

function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#FCB426',
        },
      }}
    >
      <Routes>
        <Route exact path="client">
          <Route exact path=":merchantId" element={<CheckoutPage />} />
        </Route>
        <Route path="*" element={<AuthenticatedRoute />} />
        <Route path="receipt">
          <Route path="" element={<ReceiptPage />} />
          <Route path=":receiptId" element={<ReceiptPage />} />
        </Route>
      </Routes>
    </ConfigProvider>
  );
}

export default App;
