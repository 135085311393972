import { Modal, Result } from "antd";
import React from "react";

export default function FailedModal(props) {

    return(
        <Modal
        title='Failed'
        centered
        open={props.modalVisible}
        onCancel={() => props.onCancel()}
        footer={null}
        afterClose={props.afterClose}
        >
            
            <Result
    status="error"
    title="Payment Failed" 
    subTitle="An error occured while processing your payment please try again"
  />
        </Modal>
    )
}