import axios from 'axios'

export default async function checkStatus(token, transactionId) {



    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/api/transactions/${transactionId}/status`,
      headers: { 
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    };

    try {
        const response = await axios(config)
        console.log(response.data);
        return response.data
    } catch (error) {
        console.log(error)
    }

}
