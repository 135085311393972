import { Text, Metric, BarChart } from "@tremor/react";
import moment from "moment";
import { numberWithCommas } from "../../../../Helper";


export const sampleData = [
    {
        "id": 2,
        "amount": "0.01",
        "transactionFee": null,
        "elevyAmount": null,
        "customerNumber": "0542514205",
        "reference": "isaac test",
        "status": "successful",
        "momoReference": "0ec81dec6142414ea7f82957c",
        "transactionId": "529afb3369584b0193231e362a8ddbfc",
        "createdAt": "2023-04-14T10:19:09.000Z",
        "updatedAt": "2022-10-14T10:19:29.000Z",
        "MerchantId": 417,
        "UserId": 14,
        "CollectionChannelId": 1
    },
    {
        "id": 3,
        "amount": "0.01",
        "transactionFee": null,
        "elevyAmount": null,
        "customerNumber": "0501400337",
        "reference": "kelvin test",
        "status": "successful",
        "momoReference": "a430d2000b584db3bdf847359",
        "transactionId": "1a53ddd51fff412e9dbc58b2a4336c21",
        "createdAt": "2022-10-14T10:21:46.000Z",
        "updatedAt": "2022-10-14T10:22:08.000Z",
        "MerchantId": 417,
        "UserId": 14,
        "CollectionChannelId": 3
    },
    {
        "id": 17,
        "amount": "1.00",
        "transactionFee": null,
        "elevyAmount": null,
        "customerNumber": "0244187179",
        "reference": "lk",
        "status": "failed",
        "momoReference": "ec15b9c056b44002968418ae9",
        "transactionId": "422925bbea91451bb35558aca53b9e78",
        "createdAt": "2022-10-19T11:05:22.000Z",
        "updatedAt": "2022-10-19T11:17:20.000Z",
        "MerchantId": 417,
        "UserId": 16,
        "CollectionChannelId": 1
    },
    {
        "id": 18,
        "amount": "50.00",
        "transactionFee": null,
        "elevyAmount": null,
        "customerNumber": "0244187179",
        "reference": "Electrical Items",
        "status": "failed",
        "momoReference": "a25dfb66e9b44aafb650c53bb",
        "transactionId": "7d7dddb2bf754dd890dd993baca87a75",
        "createdAt": "2022-10-19T12:39:38.000Z",
        "updatedAt": "2022-10-19T12:39:41.000Z",
        "MerchantId": 417,
        "UserId": 16,
        "CollectionChannelId": 1
    },
    {
        "id": 18,
        "amount": "50.00",
        "transactionFee": null,
        "elevyAmount": null,
        "customerNumber": "0244187179",
        "reference": "Electrical Items",
        "status": "failed",
        "momoReference": "a25dfb66e9b44aafb650c53bb",
        "transactionId": "7d7dddb2bf754dd890dd993baca87a75",
        "createdAt": "2022-10-19T21:39:38.000Z",
        "updatedAt": "2022-10-19T21:39:41.000Z",
        "MerchantId": 417,
        "UserId": 16,
        "CollectionChannelId": 1
    }
]

const dataFormatter = (number) => {
    return "₵ " + Intl.NumberFormat("us").format(number).toString();
};

const timeOfDayRange = (time) => {
    switch (true) {
        case (time <= '11'):
            return 'Morning'

        case (time <= '16'):
            return 'Afternoon'

        case (time <= '20'):
            return 'Evening'
        default:
            return 'Night'
    }
}

const weekOfMonthRange = (day) => {
    switch (true) {
        case (day <= '6'):
            return 'Week 1'

        case (day <= '13'):
            return 'Week 2'

        case (day <= '20'):
            return 'Week 3'

        case (day <= '27'):
            return 'Week 4'

        case (day <= '31'):
            return 'Week 5'
        default:
            return 'Night'
    }
}

const getWeekOfMonth = (test) => {

    const firstDayOfMonth = moment().clone().startOf('month')
    const firstDayOfWeek = moment().clone().startOf('week')

    const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');

    const weekOftheMonth = Math.ceil((moment(test) + offset) / 7);

    const a = moment(test.createdAt)
    console.log('firstDayOfMonth', firstDayOfMonth);
    console.log('firstDayOfWeek', firstDayOfWeek);
    console.log('offset', offset);
    console.log('weekOftheMonth', weekOftheMonth);
    console.log(a);
    
}


// getWeekOfMonth(sampleData[0])

const formatDataForDay = (data) => {

    const t = data?.map(item => ({ name: timeOfDayRange(moment(item?.createdAt).format('H')), amount: parseFloat(item?.amount) }))
    const r = t?.reduce((prev, curr) => {
        const target = prev.find((item) => item.name === curr.name);

        if (target) {
            Object.assign(target, {
                ...curr,
                amount: curr.amount + target.amount
            })
        } else {
            prev.push(curr)
        }
        return prev
    }, [{ name: 'Morning', amount: 0 }, { name: 'Afternoon', amount: 0 }, { name: 'Evening', amount: 0 }, { name: 'Night', amount: 0 }])
    return r
}

const formatDataForWeek = (data) => {

    const t = data?.map(item => ({ name: moment(item?.createdAt).format('dddd'), amount: parseFloat(item?.amount) }))
    const r = t?.reduce((prev, curr) => {
        const target = prev.find((item) => item?.name === curr?.name);

        if (target) {
            Object.assign(target, {
                ...curr,
                amount: curr.amount + target.amount
            })
        } else {
            prev.push(curr)
        }
        return prev
    }, [{ name: 'Sunday', amount: 0 }, { name: 'Monday', amount: 0 }, { name: 'Tuesday', amount: 0 }, { name: 'Wednesday', amount: 0 }, { name: 'Thursday', amount: 0 }, { name: 'Friday', amount: 0 }, { name: 'Saturday', amount: 0 }])
    return r
}


const formatDataForMonth = (data) => {

    const t = data?.map(item => ({ name: weekOfMonthRange(moment(item?.createdAt).format('D')), amount: parseFloat(item?.amount) }))
    const r = t?.reduce((prev, curr) => {
        const target = prev.find((item) => item?.name === curr?.name);

        if (target) {
            Object.assign(target, {
                ...curr,
                amount: curr?.amount + target?.amount
            })
        } else {
            prev.push(curr)
        }
        return prev
    }, [{ name: 'Week 1', amount: 0 }, { name: 'Week 2', amount: 0 }, { name: 'Week 3', amount: 0 }, { name: 'Week 4', amount: 0 }, { name: 'Week 5', amount: 0 }])
    return r
}

const TremorBarchart = (props) => {
    const data = props.data?.filter(a => a.status === 'successful'),
        segment = props.segment,
        value = props.value
    return (<div>
        <Text> Collected {segment} </Text>
        <Metric>₵ {numberWithCommas(value)} </Metric>
        <BarChart
            data={{ 'Today': formatDataForDay(data), 'This week': formatDataForWeek(data), 'This month': formatDataForMonth(data) }[segment]}
            dataKey="name"
            categories={["amount"]}
            colors={["teal"]}
            valueFormatter={dataFormatter}
            showLegend={false}
            yAxisWidth="w-12"
            height="h-52"
            marginTop="mt-2.5"
        />
    </div>)
}


export default TremorBarchart;

