import { Row, Col, Card, Typography, Segmented, Button, Dropdown, Avatar, Input, Menu, Pagination, Select, DatePicker, Checkbox } from "antd";
import AlertPopup from "../../result/AlertPopup";
import TableView from "../dashboard/charts/TableView";
import TransactionTable from "../dashboard/TransactionTable";
import moment from 'moment'
import { useMsal } from "@azure/msal-react";
import React, { useState } from "react";
import { DownloadOutlined } from '@ant-design/icons'
import dayjs from "dayjs";
import { exportToCSV } from "../../../services/exportToCsv";
const { Text } = Typography;
const { RangePicker } = DatePicker;

export default function Transactions() {

    const [startDate, setStartDate] = useState(moment().startOf('day').toISOString());
    const [endDate, setEndDate] = useState(moment().endOf('day').toISOString());
    const [selectedSegement, setSelectedSegement] = useState('Daily')
    const [refresh, setRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [isOnboarded, setIsOnboarded] = useState(true)
    const [dropdownValue, setDropdownValue] = useState("all")
    const [useDatePicker, setUseDatePicker] = useState(false)

    const { instance, accounts, inProgress } = useMsal();


    const handleSegement = (option) => {
        setSelectedSegement(option);
        switch (option) {
            case 'Daily':
                setStartDate(moment().startOf('day').toISOString())
                setEndDate(moment().endOf('day').toISOString());
                break;

            case 'Weekly':
                setStartDate(moment().startOf('week').toISOString())
                setEndDate(moment().endOf('week').toISOString());
                break;

            case 'Monthly':
                setStartDate(moment().startOf('month').toISOString())
                setEndDate(moment().endOf('month').toISOString());
                break;

            case 'Quarterly':
                setStartDate(moment().startOf('quarter').toISOString())
                setEndDate(moment().endOf('quarter').toISOString());
                break;

            case 'Yearly':
                setStartDate(moment().startOf('year').toISOString())
                setEndDate(moment().endOf('year').toISOString());
                break;

            default:
                break;
        }
    }

    const handleLogout = () => {
        instance.logoutRedirect().then(response => {
            console.log(response);
        })
    }

    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <div>
                            {accounts[0]?.name}
                        </div>
                    ),
                    disabled: true
                },
                {
                    key: '2',
                    label: (
                        <Button
                            onClick={() => handleLogout()}
                            type="text"
                        >
                            SignOut
                        </Button>



                    ),
                }
            ]}
        />
    );

    function handleLoading(state) {
        setIsLoading(state)
    }
    return (
        <div className="dashboard-home">
            {!isOnboarded && <AlertPopup />}
            <div
                style={{
                    // paddingLeft: '2%'
                }}>
                <h3>Transactions</h3>
                <Text type="secondary">View report based on current progresses.</Text>
                <br />
                <Segmented
                    disabled={useDatePicker}
                    className="mt-3 extra-buttons"
                    onChange={(value) => handleSegement(value)}
                    options={['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly']}
                />

                <RangePicker style={{ marginLeft: '10px' }}
                    disabled={!useDatePicker}
                    defaultValue={[dayjs(), dayjs()]}
                    onChange={(e) => {
                        const sd = dayjs(e[0]).startOf('day').toISOString()
                        const ed = dayjs(e[1]).startOf('day').toISOString()
                        setStartDate(sd)
                        setEndDate(ed)

                    }}
                />

                <Checkbox checked={useDatePicker}
                    onChange={(e) => setUseDatePicker(e.target.checked)}
                    style={{ marginLeft: '10px' }}>Use date picker</Checkbox>

            </div>

            <Button
                className="extra-buttons"
                onClick={() => setRefresh(!refresh)}
                size="large"
                style={{
                    top: 90,
                    right: 70,
                    position: 'absolute',
                    backgroundColor: '#FCB426',
                    border: 'none',
                    color: 'black'
                }}>Refresh</Button>


            <div className="extra-buttons">
                <Input
                    allowClear
                    placeholder="Search"
                    style={{
                        border: 'solid #fff',
                        position: 'absolute',
                        top: 20,
                        right: 130,
                        width: '15%',
                        visibility: 'hidden'
                    }}
                />
                <Dropdown
                    menu={menu}>
                    <Avatar
                        size="large"
                        style={{
                            backgroundColor: "#f56a00",
                            position: 'absolute',
                            top: 20,
                            right: 70,
                        }}
                    // onClick={() => { console.log('avatar clicked'); }}
                    >
                        {accounts[0]?.name?.charAt(0)}
                    </Avatar>
                </Dropdown>
            </div>

            <Button
                className="dashboard-newbuttons"
                disabled={!isOnboarded}
                size="large"
                style={{
                    backgroundColor: isOnboarded ? "#FCB426" : "#f8f9fa",
                    border: 'none',
                    position: 'absolute',
                    top: 30,
                    right: 20,
                    color: isOnboarded && 'black'

                }}
            // onClick={() => { props.handleCollectClicked() }}
            >

                Refresh
            </Button>




            <Row gutter={16} className="mt-3">

                <Col span={24} xl={24} lg={24} xs={0} sm={0}>
                    <Card
                        loading={isLoading}
                        bordered={false}
                        style={{
                            borderRadius: '5px',
                            height: '75vh',
                            overflow: "hidden"
                        }}
                    >
                        <TransactionTable
                            updateLoading={handleLoading}
                            refresh={refresh}
                            selectedSegement={selectedSegement}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    </Card>
                </Col>
                <Col span={10} xl={0} lg={0} xs={24} sm={24}>
                    <Card
                        className="dashboard-newcard"
                        loading={isLoading}
                        style={{
                            height: '100%',
                            borderRadius: '10px',
                            overflow: 'hidden',
                            textAlign: 'left'
                        }}
                    >
                        <h3 className="mb-2">{selectedSegement}</h3>
                        <Pagination defaultCurrent={1} total={50} style={{ position: 'absolute', bottom: 20, right: 20 }} />
                        <Select
                            value={dropdownValue}
                            style={{ width: '20%', position: 'absolute', top: 20, right: 20 }}
                            options={[
                                {
                                    label: 'All',
                                    value: 'all',
                                },
                                {
                                    label: 'Successful',
                                    value: 'successful',
                                },
                                {
                                    label: 'Failed',
                                    value: 'failed',
                                },
                                {
                                    label: 'Pending',
                                    value: 'pending',
                                }
                            ]}

                            onChange={(value) => { setDropdownValue(value) }}
                        />

                        <TableView
                            height={"380px"}
                            status={dropdownValue}
                            refresh={refresh}
                            startDate={startDate}
                            endDate={endDate}
                        />
                        {/* <TodayTable
                            refresh={refresh}
                            startDate={startDate}
                            endDate={endDate}
                        /> */}
                    </Card>
                </Col>
            </Row>

        </div>
    )
}