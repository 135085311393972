import axios from 'axios'


export default async function startCollection(token, collectionChannelId, amount, number, reference, transactionFee, elevyAmount, clientReference) {


    var data = JSON.stringify({
        "collectionChannelId": collectionChannelId,
        "amount": amount,
        "customerNumber": number,
        "reference": reference,
        "transactionFee": transactionFee,
        "elevyAmount": elevyAmount,
        "clientReference": clientReference
    });

    console.log(data)
    var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/transactions/collectionwithcharges`,
        headers: {
            'Authorization': 'Bearer ' + token,
            'x-client-baseurl': `${process.env.REACT_APP_X_CLIENT_BASE_URL}`,
            'Content-Type': 'application/json'
        },
        data: data
    };

    try {
        const response = await axios(config)
        console.log(response.data);
        return response.data
    } catch(error) {
        console.log(error);
    }


}