import { Avatar, Button, Card, Col, Dropdown, Input, Pagination, Row, Segmented, Select, Typography, Menu, DatePicker, Checkbox } from "antd";
import { useState } from "react";
import moment, { now } from 'moment'
import TransactionTable from "../dashboard/TransactionTable";
import TableView from "../dashboard/charts/TableView";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { protectedResources } from "../../../authConfig";
import { InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import axios from "axios";
import dayjs from "dayjs";
const { Text } = Typography;
const { RangePicker } = DatePicker;



const Branch = () => {
    const [selectedSegement, setSelectedSegement] = useState('Daily')
    const [startDate, setStartDate] = useState(moment().startOf('day').toISOString());
    const [endDate, setEndDate] = useState(moment().endOf('day').toISOString());
    const [refresh, setRefresh] = useState(false);
    const [dropdownValue, setDropdownValue] = useState("all")
    const [isLoading, setIsLoading] = useState(false)
    const [isOnboarded, setIsOnboarded] = useState(true)
    const [branches, setBranches] = useState(null)
    const [data, setData] = useState(null)
    const [merchantId, setMerchantId] = useState(null)
    const [useDatePicker, setUseDatePicker] = useState(false)

    const [userAssignedMerchant, setUserAssignedMerchant] = useState({})

    const { instance, accounts, inProgress } = useMsal();

    const handleSegement = (option) => {
        setSelectedSegement(option);
        switch (option) {
            case 'Daily':
                setStartDate(moment().startOf('day').toISOString())
                setEndDate(moment().endOf('day').toISOString());
                break;

            case 'Weekly':
                setStartDate(moment().startOf('week').toISOString())
                setEndDate(moment().endOf('week').toISOString());
                break;

            case 'Monthly':
                setStartDate(moment().startOf('month').toISOString())
                setEndDate(moment().endOf('month').toISOString());
                break;

            case 'Quarterly':
                setStartDate(moment().startOf('quarter').toISOString())
                setEndDate(moment().endOf('quarter').toISOString());
                break;

            case 'Yearly':
                setStartDate(moment().startOf('year').toISOString())
                setEndDate(moment().endOf('year').toISOString());
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        // get user assigned merchant
        handleGetUserAssignedMerchant()

    }, [])

    async function handleGetUserAssignedMerchant() {

        setIsLoading(true)
        setUserAssignedMerchant({})
        instance.acquireTokenSilent({
            scopes: protectedResources.basicAccess.scopes,
            account: accounts[0]
        }).then(async (response) => {
            //get accessToken
            // loadRequests(response.accessToken)
            try {
                const assignedMerchantResponse = await axios({
                    method: 'get',
                    url: process.env.REACT_APP_API_URL + '/api/user/merchant',
                    headers: {
                        'Authorization': 'Bearer ' + response.accessToken
                    }
                })

                const accountNumber = assignedMerchantResponse.data?.data?.merchant?.accountNumber
                // console.log(accountNumber);

                //get merchant by account number

                const merchantByAccountNumberResponse = await axios({
                    method: 'get',
                    url: process.env.REACT_APP_API_URL + '/api/merchants/accountNumber/' + accountNumber,
                    headers: {
                        'Authorization': 'Bearer ' + response.accessToken
                    }
                })
                let options = []
                // console.log(merchantByAccountNumberResponse.data.data);
                merchantByAccountNumberResponse.data?.data.forEach((data, index) => {
                    options.push({
                        value: data.id,
                        label: data.name
                    })
                })

                setBranches(options)

            } catch (error) {

            }


        }).catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
                if (accounts[0] && inProgress === InteractionStatus.None) {
                    instance.acquireTokenPopup({
                        scopes: protectedResources.basicAccess.scopes,
                    }).then(response => {

                    }).catch(error => {


                    });
                }
            }
        })
    }



    function handleLoading(state) {
        setIsLoading(state)
    }

    const handleLogout = () => {
        instance.logoutRedirect().then(response => {
            console.log(response);
        })
    }

    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <div>
                            {accounts[0]?.name}
                        </div>
                    ),
                    disabled: true
                },
                {
                    key: '2',
                    label: (
                        <Button
                            onClick={() => handleLogout()}
                            type="text"
                        >
                            SignOut
                        </Button>



                    ),
                }
            ]}
        />
    );


    return (<div>

        <div
            style={{
                // paddingLeft: '2%'
            }}>
            <h3>Transactions by Branch</h3>
            <Text type="secondary">View report based on current progresses.</Text>
            <br />
            <Segmented
                disabled={useDatePicker}
                className="mt-3 extra-buttons"
                onChange={(value) => handleSegement(value)}
                options={['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly']}
            />
             <RangePicker style={{marginLeft: '10px'}} 
             disabled={!useDatePicker}
             defaultValue={[dayjs(), dayjs()]}
             onChange={(e) => {
                const sd = dayjs(e[0]).startOf('day').toISOString()
                const ed = dayjs(e[1]).startOf('day').toISOString()
                setStartDate(sd)
                setEndDate(ed)
                
            }}
             />
             <Checkbox checked={useDatePicker} 
             onChange={(e) => setUseDatePicker(e.target.checked)} 
             style={{marginLeft: '10px'}}>Use date picker</Checkbox>
        </div>

        <div style={{
        }}>
       
        </div>

        <div
            style={{
                width: '22%',
                display: 'block',
                marginLeft: 'auto'
            }}
        >
            <Text type="secondary">Branches:</Text>
            <Select
                placeholder="Current Branch"
                showSearch
                style={{
                    width: '100%'
                }}
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                onChange={(branch) => {
                    console.log(branch);
                    setMerchantId(branch)
                    setRefresh(!refresh)
                }}
                loading={!branches}
                options={branches} />
        </div>


        <Button
            className="extra-buttons"
            onClick={() => setRefresh(!refresh)}
            size="large"
            style={{
                top: 90,
                right: 70,
                position: 'absolute',
                backgroundColor: '#FCB426',
                border: 'none',
                color: 'black'
            }}>Refresh</Button>

        <div className="extra-buttons">
            <Input
                allowClear
                placeholder="Search"
                style={{
                    border: 'solid #fff',
                    position: 'absolute',
                    top: 20,
                    right: 130,
                    width: '15%',
                    visibility: 'hidden'
                }}
            />
            <Dropdown
                menu={menu}>
                <Avatar
                    size="large"
                    style={{
                        backgroundColor: "#f56a00",
                        position: 'absolute',
                        top: 20,
                        right: 70,
                    }}
                // onClick={() => { console.log('avatar clicked'); }}
                >
                    {accounts[0]?.name?.charAt(0)}
                </Avatar>
            </Dropdown>
        </div>

        {/* <Input
            style={{
                position: 'absolute',
                top: 30,
                right: 150,
                width: '15%'
            }}
        /> */}

        <Button
            className="dashboard-newbuttons"
            disabled={!isOnboarded}
            size="large"
            style={{
                backgroundColor: isOnboarded ? "#FCB426" : "#f8f9fa",
                border: 'none',
                position: 'absolute',
                top: 30,
                right: 20,
                color: isOnboarded && 'black'

            }}
        // onClick={() => { props.handleCollectClicked() }}
        >
            Refresh
        </Button>
        <Row gutter={16} className="mt-3">

            <Col span={24} xl={24} lg={24} xs={0} sm={0}>
                <Card
                    loading={isLoading}
                    bordered={false}
                    style={{
                        borderRadius: '5px',
                        height: '71vh',
                        overflow: "hidden"
                    }}
                >
                    <TransactionTable
                        updateLoading={handleLoading}
                        refresh={refresh}
                        selectedSegement={selectedSegement}
                        startDate={startDate}
                        endDate={endDate}
                        merchantId={merchantId}
                    />
                </Card>
            </Col>
            <Col span={10} xl={0} lg={0} xs={24} sm={24}>
                <Card
                    className="dashboard-newcard"
                    loading={isLoading}
                    style={{
                        height: '100%',
                        borderRadius: '10px',
                        overflow: 'hidden',
                        textAlign: 'left'
                    }}
                >
                    <h3 className="mb-2">{selectedSegement}</h3>
                    <Pagination defaultCurrent={1} total={50} style={{ position: 'absolute', bottom: 20, right: 20 }} />
                    <Select
                        value={dropdownValue}
                        style={{ width: '20%', position: 'absolute', top: 20, right: 20 }}
                        options={[
                            {
                                label: 'All',
                                value: 'all',
                            },
                            {
                                label: 'Successful',
                                value: 'successful',
                            },
                            {
                                label: 'Failed',
                                value: 'failed',
                            },
                            {
                                label: 'Pending',
                                value: 'pending',
                            }
                        ]}

                        onChange={(value) => { setDropdownValue(value) }}
                    />

                    <TableView
                        height={"380px"}
                        status={dropdownValue}
                        refresh={refresh}
                        startDate={startDate}
                        endDate={endDate}
                        merchantId={merchantId}
                    />
                    {/* <TodayTable
                            refresh={refresh}
                            startDate={startDate}
                            endDate={endDate}
                        /> */}
                </Card>
            </Col>
        </Row>

    </div>)
}

export default Branch;