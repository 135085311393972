import { Card, Typography } from 'antd'
import { CheckCircleFilled, MinusCircleFilled } from '@ant-design/icons'

import React from "react";

const { Text } = Typography;

export default function PaymentOptionCard(props) {

    const selected = props.selected

    return (
        <div>
            <Card
                hoverable={true}
                style={{
                    width: '130px',
                    height: '100px',
                    display: "inline-block",
                    margin: '0px',
                    backgroundColor: '#f8f9fa',
                    border: selected ? '#FCB426 solid 2px' : 'none',
                    borderRadius: '10px'
                }}
                onClick={props.onClick}
            >
                <Text
                    style={{
                        position: 'absolute',
                        top: 10,
                        left: 10,
                        textAlign: 'left',
                        width: '70%'
                    }}

                >{props.title}</Text>

                <img
                    alt={props.alt}
                    src={props.image}
                    style={{
                        position: 'absolute',
                        width: 40,
                        height: 40,
                        bottom: 10,
                        right: 10,
                        borderRadius: '100%',
                        objectFit: 'cover'
                    }}
                />
                {selected ? <CheckCircleFilled
                    style={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        color: '#FCB426'
                    }} /> : <MinusCircleFilled
                    style={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        color: '#D8D8D8'
                    }}
                />}

                {/*  */}


            </Card>
        </div>
    )
}