import axios from "axios";

export default async function getMerchantById(id) {


    var config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/openapi/merchants?id=${id}`,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    try {
        const response = await axios(config)
        console.log(response.data);
        return response.data
    } catch (error) {
        console.log(error);
    }

}