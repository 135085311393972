import React from "react";
import { loginRequest, protectedResources } from "./authConfig";
import { InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser'
import { Route, Routes, Navigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import MerchantPage from "./pages/MerchantPage";


export default function AuthenticatedRoute() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, account, inProgress } = useMsal();

  const handleLogin = (instance) => {
    instance.loginRedirect(loginRequest).catch(e => {
      console.log(e);
    }).catch(async error => {
      if (error instanceof InteractionRequiredAuthError) {
        if (account && inProgress === InteractionStatus.None) {
          instance.acquireTokenRedirect({
            scopes: protectedResources.apiHello.scopes,
          }).then(_response => {
          }).catch(error => console.log(error));
        }
      }
    });
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/merchant/dashboard" replace />} />
      {isAuthenticated ?
        <Route exact path="merchant">
          <Route path="dashboard" element={<MerchantPage selected={1} />} />
          <Route path="transactions" element={<MerchantPage selected={2} />} />
          <Route path="branch" element={<MerchantPage selected={3} />} />
          <Route path="profile" element={<MerchantPage selected={4} />} />
        </Route>
        : handleLogin(instance)}
    </Routes>
  )

}