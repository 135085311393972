import { Typography } from "antd";
import React from "react";

const { Text } = Typography;
export default function Footer() {

    return(
        <div 
        className="mb-3"
        style={{
            marginTop: '3%'
        }}>
            <Text>Powered by UMB Digital Transformation</Text>
        </div>
    )
}