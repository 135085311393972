import { Space, Table, Tag, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react';
import { InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from "@azure/msal-react";
import { protectedResources } from '../../../authConfig';
import listTransactions from '../../../api/listTransactions';
import moment from 'moment'
import TransactionDetails from '../Transactions/TransactionDetails';
import axios from 'axios';
import { exportToCSV } from '../../../services/exportToCsv';


export default function TransactionTable(props) {

  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [transactionInfo, setTransactionInfo] = useState({})
  const { instance, accounts, inProgress } = useMsal();

  // const []


  useEffect(() => {
    // console.log(props?.merchantId);
    if (props.merchantId) {
      getTransactionByMerchantId(props.merchantId)
    } else {
      handleListTransactionsAPI()
    }
  }, [props?.refresh, props?.startDate])

  const handleListTransactionsAPI = () => {
    setIsLoading(true)
    // props?.updateLoading(true)
    console.log('Calling transactions without branch');
    instance.acquireTokenSilent({
      scopes: protectedResources.basicAccess.scopes,
      account: accounts[0]
    }).then(response => {
      //get accessToken
      // console.log(response.accessToken
      // loadRequests(response.accessToken)
      listTransactions(response.accessToken, props?.startDate, props?.endDate).then(result => {

        // console.log(result);
        setIsLoading(false)
        setData(result?.data)
        props?.updateLoading(false)
      }).catch(error => {
        console.log(error)
        setIsLoading(false)
      })
    }).catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
        if (accounts[0] && inProgress === InteractionStatus.None) {
          instance.acquireTokenPopup({
            scopes: protectedResources.basicAccess.scopes,
          }).then(response => {
            listTransactions(response.accessToken, props?.startDate, props?.endDate).then(result => {
              // console.log(result);
              setIsLoading(false)
              setData(result?.data)
              props?.updateLoading(false)
            }).catch(error => {
              console.log(error);
              setIsLoading(false)

            })

          }).catch(error => {
            setIsLoading(false)
            console.log(error)

          });
        }
      }
    })
  }

  const getTransactionByMerchantId = (merchantId) => {
    setIsLoading(true)
    // props?.updateLoading(true)
    console.log('Calling transactions with branch');
    instance.acquireTokenSilent({
      scopes: protectedResources.basicAccess.scopes,
      account: accounts[0]
    }).then(async (response) => {
      //get accessToken
      // console.log(response.accessToken
      try {

        const transactionsResponse = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_API_URL}/api/transactions/merchantId/${merchantId}?fromDate=${props?.startDate}&toDate=${props?.endDate}`,
          headers: {
            'Authorization': 'Bearer ' + response.accessToken
          }
        })

        console.log(transactionsResponse?.data?.data);

        setIsLoading(false)
        setData(transactionsResponse?.data?.data)
        props?.updateLoading(false)


      } catch (error) {
        setIsLoading(false)
        console.log(error.message);
      }

    }).catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
        if (accounts[0] && inProgress === InteractionStatus.None) {
          instance.acquireTokenPopup({
            scopes: protectedResources.basicAccess.scopes,
          }).then(async (response) => {

            try {

              const transactionsResponse = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/api/transactions/merchantId/${merchantId}?fromDate=${props?.startDate}&toDate=${props?.endDate}`,
                headers: {
                  'Authorization': 'Bearer ' + response.accessToken
                }
              })

              console.log(transactionsResponse?.data?.data);

              setIsLoading(false)
              setData(transactionsResponse?.data?.data)
              props?.updateLoading(false)


            } catch (error) {
              setIsLoading(false)
              console.log(error.message);
            }

          }).catch(error => {
            setIsLoading(false)
            console.log(error)

          });
        }
      }
    })
  }


  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Customer Number',
      dataIndex: 'customerNumber',
      key: 'customerNumber',
      render: (item) => {
        return <div>{item}</div>
      }
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
      key: 'reference',
      render: (item) => {
        return <div color={['volcano', 'green', 'geekblue'][Math.floor(Math.random() * 3)]}>{item}</div>
      }
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      // key: 'updatedAt',
      sorter: (a, b) => moment(a?.createdAt).unix() - moment(b?.createdAt).unix(),
      defaultSortOrder: 'descend',
      sortDirections: ['descend'],
      render: (item) => {
        return <div>{
          moment(item).format("hh:mm a (MMM DD)")
        }</div>
      }
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (item) => {
        return <Tag color={item === "pending" ? "geekblue" : item === "failed" ? "red" : "green"}>{item}</Tag>
      },
      filters: [
        {
          text: <span>Pending</span>,
          value: 'pending',
        },
        {
          text: <span>Successful</span>,
          value: 'successful',
        },
        {
          text: <span>Failed</span>,
          value: 'failed',
        }
      ],
      onFilter: (value, record) => record.status.startsWith(value),
      filterSearch: true,
    },
  ];

  return (
    <div>
      <TransactionDetails
        transactionInfo={transactionInfo}
        modalVisible={modalVisible}
        onCancel={() => setModalVisible(false)}
      />

      <Button
        // disabled={data}
        className="extra-buttons"
        onClick={() => {
          exportToCSV({
            data: data,
            filename: 'test'
          })
        }}
        size="small"
        style={{
          marginBottom: '10px',
          display: 'block',
          marginLeft: 'auto',
          border: 'none',
          color: 'black'
        }}> <DownloadOutlined /> Download Report</Button>


      <Table
        scroll={{
          y: 420,
          x: 300
        }}
        columns={columns}
        dataSource={data}
        loading={isLoading}
        onRow={(record, index) => {
          return {
            onClick: event => { setModalVisible(true); console.log("row click"); setTransactionInfo(record) }, // click row
          }
        }}
      />


    </div>

  )
}
