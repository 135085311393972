import { Layout, Result, Spin } from "antd";
import React from "react";
import CheckoutCard from "../components/client/CheckoutCard";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import 'bootstrap/dist/css/bootstrap.min.css';
import Banner from "../components/client/Banner";
import Footer from "../components/client/Footer";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import getMerchantById from "../api/getMerchantById";
import NotFoundCard from "../components/result/NotFoundCard";
const { Content } = Layout;

export default function CheckoutPage() {

    let { merchantId } = useParams();
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState(null)

    useEffect(() => {
        setIsLoading(true)
        getMerchantById(merchantId).then(result => {
            if (result.status) {
                setData(result?.data)
            }
            console.log(result)
            setIsLoading(false)

        }).catch(error => {
            console.log(error);
            setIsLoading(false)
        })

    }, [])

    return (
        <div>
            <Layout
                className="layout"
                style={{
                    minHeight: '100vh',
                    width: '100%',
                    overflow: 'hidden'
                }}>
                <Content>
                    <Container>
                        <Row>
                            <Col style={{ textAlign: "Center" }}>
                                <Banner />
                               {!isLoading ? data !== null ? <CheckoutCard data={data} merchantId={merchantId}/> : <NotFoundCard title="Merchant not found" subTitle={`Merchant with id ${merchantId} does not exist`}/>: <Spin />}
                                <Footer />
                            </Col>
                        </Row>
                    </Container>
                </Content>

                {/* <img src={require("../images/pattern.png")} alt="background" style={{
                    position: 'absolute',
                    bottom: '0',
                    left: 0,
                    right: 0,
                    height: '30vh'
                }} /> */}
            </Layout>
        </div>
    )
}