import axios from 'axios'

export default async function getTransationByRefNoAuth(reference) {


    var config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/openapi/transactions?reference=${reference}`,
        // url: `https://umb-speedpay-api-dev.azurewebsites.net/openapi/transactions?reference=${reference}`,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    try {
        const response = await axios(config);
        return response.data

    } catch (error) {
        console.log(error)
    }

}