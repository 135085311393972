import { Button, Col, Divider, Modal, Row, Typography, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { numberWithCommas } from "../../Helper";
import FailedModal from "../result/FailedModal";
import SuccessModal from "../result/SuccessModal";
import { protectedResources } from "../../authConfig";
import startCollection from "../../api/startCollection";
import { InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import startCollectionNoAuth from "../../api/startCollectionNoAuth";

const { Text } = Typography;

export default function ConfirmationModal(props) {

    const customerData = props?.customerData;
    const [modalVisible, setModalVisible] = useState(false);
    const [failedModalVisible, setFailedModalVisible] = useState(false)
    const [collectionData, setCollectionData] = useState({})
    const [total, setTotal] = useState('')
    // const []
    const [isLoading, setIsLoading] = useState(false)

    const { instance, accounts, inProgress } = useMsal();

    const handleCancel = () => {
        setModalVisible(false);
    }

    useEffect(() => {

        const total = parseFloat(customerData?.amount) + parseFloat(props?.feesInformation?.transactionFee) + parseFloat(props?.feesInformation?.elevyAmount)
        console.log(total);
        setTotal(total)
    }, [props?.feesInformation])

    const handleCollectAPI = () => {
        // console.log([props?.selectedPaymentOption?.key, customerData?.amount, customerData?.number, customerData?.reference]);
        setIsLoading(true)
        instance.acquireTokenSilent({
            scopes: protectedResources.basicAccess.scopes,
            account: accounts[0]
        }).then(response => {
            //get accessToken
            // console.log(response.accessToken
            // loadRequests(response.accessToken)

            startCollection(response.accessToken,
                props?.selectedPaymentOption?.key,
                customerData?.amount,
                customerData?.number,
                customerData?.reference,
                props?.feesInformation?.transactionFee,
                props?.feesInformation?.elevyAmount,
                props?.feesInformation?.clientReference
            ).then(result => {

                console.log(result);
                setIsLoading(false)

                setCollectionData(result?.data)

                if (result.status) {
                    setModalVisible(true)
                } else {
                    setFailedModalVisible(true)
                }

            }).catch(error => {
                console.log(error)
                setIsLoading(false)
                setFailedModalVisible(true)
            })
        }).catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
                if (accounts[0] && inProgress === InteractionStatus.None) {
                    instance.acquireTokenPopup({
                        scopes: protectedResources.basicAccess.scopes,
                    }).then(response => {

                        startCollection(
                            response.accessToken,
                            props?.selectedPaymentOption?.key,
                            customerData?.amount,
                            customerData?.number,
                            customerData?.reference).then(result => {
                                console.log(result);
                                setIsLoading(false)

                                setCollectionData(result?.data)

                                if (result.status) {
                                    setModalVisible(true)
                                } else {
                                    setFailedModalVisible(true)
                                }
                            }).catch(error => {
                                console.log(error);
                                setIsLoading(false)
                                setFailedModalVisible(true)
                            })

                    }).catch(error => {
                        setIsLoading(false)
                        console.log(error)
                        setFailedModalVisible(true)
                    });
                }
            }
        })
    }

    const handleCollectAPINoAuth = () => {
        setIsLoading(true)
        startCollectionNoAuth(
            props?.merchantId, 
            props?.selectedPaymentOption?.key, 
            customerData?.amount, 
            customerData?.number, 
            customerData?.reference,
            props?.feesInformation?.transactionFee,
            props?.feesInformation?.elevyAmount,
            props?.feesInformation?.clientReference
            ).then(result => {
            console.log(result);
            setIsLoading(false)

            setCollectionData(result?.data)

            if (result.status) {
                setModalVisible(true)
            } else {
                setFailedModalVisible(true)
            }

        }).catch(error => {
            console.log(error);
            setIsLoading(false)
            setFailedModalVisible(true)
        })
    }

    return (
        <Modal
            title="Confirm"
            centered
            open={props.modalVisible}
            // onOk={() => setModal2Visible(false)}
            onCancel={() => props.onCancel()}
            footer={null}
            afterClose={props.afterClose}
        >
            <Row gutter={20}>
                <Col>
                    <img
                        // src={require('../../images/momo.jpeg')}
                        src={props?.selectedPaymentOption?.image}
                        alt=""
                        style={{
                            width: '70px',
                            height: '70px',
                            borderRadius: '100%',
                            objectFit: 'cover'
                        }}
                    />
                </Col>
                <Col>
                    <span>
                        <p style={{ margin: 0 }}>Pay to: {props?.data?.name}</p>
                        <p className="text-muted" style={{ margin: 0 }}>From: {customerData?.number}</p>
                        <p className="text-muted">Reference: {customerData?.reference}</p>
                    </span>
                </Col>
            </Row>

            <Divider orientation="left" plain>Cost Summary</Divider>

            <Row justify="end">

                <table className="mb-5 mt-3 text-muted">
                    <tr>
                        <td style={{ textAlign: 'right' }}>Amount:</td>
                        <td style={{ textAlign: 'right' }}><Text style={{ marginLeft: '20px' }}>{customerData?.amount}</Text></td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'right' }}>Fees:</td>
                        <td style={{ textAlign: 'right' }}><Text style={{ marginLeft: '20px' }}>{parseFloat(props?.feesInformation?.transactionFee)}</Text></td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'right' }}>E Levy:</td>
                        <td style={{ textAlign: 'right' }}><Text style={{ marginLeft: '20px' }}>{parseFloat(props?.feesInformation?.elevyAmount)}</Text></td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'right' }}>Total:</td>
                        <td style={{ textAlign: 'right' }}><Text style={{ marginLeft: '20px' }} strong>{total}</Text></td>
                    </tr>
                </table>
            </Row>

            <Text style={{
                position: 'absolute',
                top: 70,
                right: 20
            }}>₵ {total}</Text>
            <Button
                loading={isLoading}
                style={{
                    width: '100%',
                    height: '40px',
                    backgroundColor: '#FCB426',
                    border: '#FCB426',
                    color: 'black'
                }}
                onClick={() =>
                // setModalVisible(true)
                {
                    if (props.source === "client") {
                        handleCollectAPINoAuth()
                    } else {
                        handleCollectAPI()
                    }
                }

                }
            >Confirm</Button>
            {/* //0501400337 */}
            <SuccessModal
                source={props?.source}
                selectedID={props?.selectedPaymentOption?.key}
                collectionData={collectionData}
                modalVisible={modalVisible}
                onCancel={() => {
                    setModalVisible(false);
                    // props?.onCancel()
                }}
                afterClose={() => props.onCancel()}
                dismissAll={() => { handleCancel() }}
            />
            <FailedModal
                modalVisible={failedModalVisible}
                onCancel={() => {
                    setFailedModalVisible(false);
                    props.onCancel()
                }}
            />

        </Modal>
    )
}