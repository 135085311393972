import { Row, Col, Card, Typography, Tag } from "antd";
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import { LineChart, Line, Tooltip, XAxis, YAxis } from 'recharts';
import React from "react";
import TransactionTable from "./TransactionTable";

const { Text } = Typography;

export default function Dashboard() {

    const data = [
        { name: 'Page A', uv: 400, pv: 2400, amt: 2400 },
        { name: 'Page B', uv: 300, pv: 300, amt: 300 },
        { name: 'Page C', uv: 350, pv: 100, amt: 100 },
        { name: 'Page d', uv: 150, pv: 100, amt: 100 },
    ];

    return (
        <div>
            <Row gutter={16}>
                <Col span={8}>
                    <Card
                        bordered={false}
                        style={{
                            height: '200px'
                        }}
                    >

                        <Text type='secondary'>Total Amount</Text>
                        <h1 className="mt-3 text-muted">GH₵ 7000</h1>
                        <Text type='secondary'
                            style={{
                                position: 'absolute',
                                bottom: 20,
                                right: 20
                            }}>Since last month</Text>

                        <Tag icon={<CaretUpOutlined />} className="mt-4" color={"#87d068"}>7.00%</Tag>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card
                        bordered={false}
                        style={{
                            height: '200px'
                        }}
                    >
                        <Text type='secondary'>Transaction Volume</Text>
                        <h1 className="mt-3 text-muted">300</h1>
                        <Text type='secondary'
                            style={{
                                position: 'absolute',
                                bottom: 20,
                                right: 20
                            }}>Since last month</Text>

                        <Tag icon={<CaretDownOutlined />} className="mt-4" color={"#f50"}>3.00%</Tag>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card
                        bordered={false}
                        style={{
                            height: '200px',
                            padding: 'none !important'
                            
                        }}
                    >

                        <Text type='secondary'>Chart</Text>

                        <LineChart width={400} height={150} data={data}>
                            <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                            {/* <XAxis dataKey="name" /> */}
                            {/* <YAxis /> */}
                            <Tooltip />
                        </LineChart>
                    </Card>
                </Col>
            </Row>
            <div
                style={{
                    marginTop: '30px',
                }}>

                <Card
                    style={{
                        height: '60vh'
                    }}>
                    <TransactionTable />
                </Card>

            </div>

        </div>
    )
}