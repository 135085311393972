import { UserOutlined, DashboardOutlined, AlignLeftOutlined, ThunderboltOutlined, BranchesOutlined } from '@ant-design/icons';
import { Layout, Menu, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import Dashboard from '../components/merchant/dashboard/Dashboard';
import { Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import './MerchantPage.css'
import CollectModal from '../components/merchant/collect/CollectModal';
import { InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import Profile from '../components/merchant/profile/Profile';
import NewDashboard from '../components/merchant/dashboard/NewDashboard';
import Transactions from '../components/merchant/Transactions/Transactions';
import Branch from '../components/merchant/branch';
import { useMsal } from "@azure/msal-react";
import axios from 'axios';
import { protectedResources } from '../authConfig';
const { Header, Content, Footer, Sider } = Layout;

export default function MerchantPage(props) {

    const { instance, accounts, inProgress } = useMsal();
    const [showModal, setShowModal] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState(props?.selected.toString())
    const [refresh, setRefresh] = useState(false)
    const [branchTab, setBranchTab] = useState(null)

    function handleCancel() {
        setShowModal(false)
    }

    function handleTransactions() {
        setSelectedMenu('2')
    }

    function handleCollectClicked() {
        setShowModal(true)
    }

    function handleRefresh() {
        setRefresh(!refresh)
    }

    let navigate = useNavigate()

    useEffect(() => {
        //check for permission

        instance.acquireTokenSilent({
            scopes: protectedResources.basicAccess.scopes,
            account: accounts[0]
        }).then(async (response) => {
            //get accessToken
            // console.log(response.accessToken
            try {
                const permissionResponse = await axios({
                    method: 'get',
                    url: process.env.REACT_APP_API_URL + '/api/users/permission',
                    headers: {
                        'Authorization': 'Bearer ' + response.accessToken
                    }
                })

                if (permissionResponse.data.status) {
                    if(permissionResponse.data?.data?.[0].isAdmin) {
                        setBranchTab({
                            key: 3,
                            icon: <BranchesOutlined />,
                            label: 'Branch'
                        })
                    }else {
                        setBranchTab(null)
                    }
                }else {
                    setBranchTab(null)
                }

            } catch (error) {
                console.log(error.message);
            }

        }).catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
                if (accounts[0] && inProgress === InteractionStatus.None) {
                    instance.acquireTokenPopup({
                        scopes: protectedResources.basicAccess.scopes,
                    }).then(async (response) => {

                        try {

                            const permissionResponse = await axios({
                                method: 'get',
                                url: process.env.REACT_APP_API_URL + '/api/users/permission',
                                headers: {
                                    'Authorization': 'Bearer ' + response.accessToken
                                }
                            })

                            console.log(permissionResponse.data);

                        } catch (error) {
                            console.log(error.message);
                        }
                    }).catch(error => {
                        console.log(error)

                    });
                }
            }
        })
    }, [])

    const renderContent = () => {

        return (
            <div>
                {
                    {
                        '1': <NewDashboard handleCollectClicked={handleCollectClicked} handleTransactions={handleTransactions} refresh={refresh} />,
                        '2': <Transactions />,
                        '3': <Branch />,
                        '4': <Profile />,
                    }[selectedMenu]
                }
            </div>
        )

    }


    return (
        <Layout>
            <Sider
                theme='light'
                breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={(broken) => {
                    // console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    // console.log(collapsed, type);
                }}
            >
                <div className="logo mt-3" >
                    <img className='mt-3 ms-3' src={require('../images/speedpay.png')} width={120} alt='' />

                </div>
                <Menu
                    theme="light"
                    mode="inline"
                    // disabled={true}
                    defaultSelectedKeys={[selectedMenu]}
                    items={[
                        {
                            key: 1,
                            icon: <DashboardOutlined />,
                            label: 'Dashboard'
                        },
                        {
                            key: 2,
                            icon: <AlignLeftOutlined />,
                            label: 'Transactions'
                        },
                        branchTab,
                        {
                            key: 4,
                            icon: <UserOutlined />,
                            label: 'Profile'
                        },

                    ]}
                    // items={[DashboardOutlined, VideoCameraOutlined, UploadOutlined].map(
                    //     (icon, index) => ({
                    //         key: String(index + 1),
                    //         icon: React.createElement(icon),
                    //         label: `nav ${index + 1}`,
                    //     }),
                    // )}
                    onSelect={item => {
                        setSelectedMenu(item.key.toString())
                        switch (item.key) {
                            case '1':
                                navigate('../dashboard')
                                break;
                            case '2':
                                navigate('../transactions')
                                break;
                            case '3':
                                navigate('../branch')
                                break;
                            case '4':
                                navigate('../profile')
                                break;
                            default:
                                break;
                        }
                    }}
                />
                <Footer
                    className='extra-buttons'
                    style={{
                        margin: '5%',
                        textAlign: 'center',
                        position: 'absolute',
                        bottom: 0,
                        // left: 0,
                        right: 0,
                        backgroundColor: '#fff',
                        // backgroundColor: 'red',
                        borderRadius: '10px'
                    }}
                >
                    <span className='text-muted' style={{ fontSize: '0.7rem' }}>
                        {/* <ThunderboltOutlined /> */}
                        Designed and developed
                        by UMB Digital Transformation</span>
                </Footer>
            </Sider>
            <Layout
                style={{

                }}
            >
                {/* <Header
                    className="site-layout-sub-header-background"
                    style={{
                        padding: 0
                    }}

                    color="#000"
                >
                    <Button
                        style={{
                            position: 'absolute',
                            right: 20,
                            top: 15,
                            backgroundColor: '#FCB426',
                            border: '#FCB426',
                            color: 'black'
                        }}
                        onClick={_ => setShowModal(true)}
                        type="primary"
                        size='large'
                    >Collect</Button>
                </Header> */}
                <Content
                    style={{
                        padding: '24px 16px 0 0',
                        minHeight: '100vh',
                        borderRadius: "24px",
                        marginLeft: ''
                    }}
                >
                    <div
                        // className="site-layout-background"
                        style={{
                            padding: 24,
                            // minHeight: '89vh',
                            height: '100%'
                        }}
                    >

                        {renderContent()}

                        <CollectModal
                            afterClose={handleRefresh}
                            showModal={showModal}
                            handleCancel={handleCancel}
                        />

                    </div>

                </Content>
            </Layout>
        </Layout>
    )
}



