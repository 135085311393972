import { Button, Modal, Typography, Form, Input, Divider } from 'antd';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React, { useState } from "react";
import PaymentOptionCard from '../../client/PaymentOptionCard';
import ConfirmationModal from '../../client/ConfirmationModal';
import { protectedResources } from '../../../authConfig';
import { InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from "@azure/msal-react";
import startCollection from '../../../api/startCollection';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
const { Text } = Typography;

export default function CollectModal(props) {

    const [selected, setSelected] = useState({})
    const [modalVisible, setModalVisible] = useState(false)
    const [customerData, setCustomerData] = useState({})
    const [isDisabled, setisDisabled] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [form] = useForm()
    const [feesInformation, setFeesInformation] = useState({
        clientReference: 'none',
        transactionFee: 0.0,
        elevyAmount: 0.0
    })

    const { instance, accounts, inProgress } = useMsal();

    const paymentOptions = [
        {
            key: '1',
            title: 'MTN Mobile Money',
            image: require('../../../images/MicrosoftTeams-image (13).png'),
            alt: 'MTN Mobile Money'
        },
        {
            key: '3',
            title: 'Vodafone Cash',
            image: require('../../../images/MicrosoftTeams-image (14).png'),
            alt: 'vf_cash'
        },
        {
            key: '2',
            title: 'AirtelTigo Money',
            image: require('../../../images/MicrosoftTeams-image (15).png'),
            alt: 'airtel_money'
        },
        {
            key: '4',
            title: 'Cash',
            image: require('../../../images/MicrosoftTeams-image (12).png'),
            alt: 'cash_collect'
        }
    ]

    const [amount, setAmount] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [reference, setReference] = useState('')

    const handleAmount = (e) => {
        setAmount(e.target.value)
    }

    const handlePhonenumber = (e) => {
        setPhoneNumber(e.target.value)
    }

    const handleReference = (e) => {
        setReference(e.target.value)
    }

    const getCollectionChannel = () => {

    }

    // reset form

    const resetFields = () => {
        setAmount(null)
        setPhoneNumber(null)
        setReference(null)

        // form.reset()
        // console.log('call reset form');
    }

    return (
        <div>
            <Modal
                afterClose={props?.afterClose}
                title="Collect"
                centered
                // open={isModalOpen} 
                open={props.showModal}
                // onOk={handleOk} 
                onCancel={() => {
                    // resetFields()
                    props.handleCancel()
                }}
                footer={null}
                width={900}
                destroyOnClose={true}
            >
                <p style={{ textAlign: 'center' }}>Kindly verify customer's details before confirming</p>

                <Form
                    className='ms-0'
                    layout='vertical'
                    // wrapperCol={{
                    //     span: 12,
                    //     offset: 6
                    // }}
                    // labelCol={{
                    //     span: 12,
                    //     offset: 6
                    // }}
                    onFinish={async (value) => {
                        if(selected.key !== '4') {
                            setIsLoading(true)
                            try {
                                const channelValue = { '1': 'mtn-gh', '3': 'vodafone-gh', '2': 'tigo-gh' }[selected.key]
    
                                instance.acquireTokenSilent({
                                    scopes: protectedResources.basicAccess.scopes,
                                    account: accounts[0]
                                }).then(response => {
                                    //get accessToken
                                    // console.log(response.accessToken
                                    // loadRequests(response.accessToken)
    
                                    axios({
                                        method: 'post',
                                        url: `${process.env.REACT_APP_API_URL}/api/transactions/getcharges`,
                                        data: {
                                            channel: channelValue,
                                            customerMsisdn: phoneNumber,
                                            amount: amount
                                        },
                                        headers: {
                                            'Authorization': 'Bearer ' + response.accessToken
                                        }
                                    }).then(value => {
        
                                        // console.log(value.data);
        
                                        if (value.data.status) {
                                            setIsLoading(false)
                                            
                                            setFeesInformation({...feesInformation, 
                                                'clientReference': value.data.data?.clientReference,
                                                'transactionFee': value.data.data?.transactionFee,
                                                'elevyAmount': value.data.data?.elevyAmount
                                            })
        
                                            setModalVisible(true)
                                        }
        
                                    })
    
    
                                }).catch(error => {
                                    if (error instanceof InteractionRequiredAuthError) {
                                        if (accounts[0] && inProgress === InteractionStatus.None) {
                                            instance.acquireTokenPopup({
                                                scopes: protectedResources.basicAccess.scopes,
                                            })
                                                .then(response => {
    
                                                    axios({
                                                        method: 'post',
                                                        url: `${process.env.REACT_APP_API_URL}/api/transactions/getcharges`,
                                                        data: {
                                                            channel: channelValue,
                                                            customerMsisdn: phoneNumber,
                                                            amount: amount
                                                        },
                                                        headers: {
                                                            'Authorization': 'Bearer ' + response.accessToken
                                                        }
                                                    }).then(value => {
                        
                                                        console.log(value.data);
                        
                                                        if (value.data.status) {
                                                            setIsLoading(false)
                                                            
                                                            setFeesInformation({...feesInformation, 
                                                                'clientReference': value.data.data?.clientReference,
                                                                'transactionFee': value.data.data?.transactionFee,
                                                                'elevyAmount': value.data.data?.elevyAmount
                                                            })
                        
                                                            setModalVisible(true)
                                                        }
                        
                                                    })
                                                })
                                        }
                                    }
                                })
                                // setCustomerData({
                                //     amount: amount,
                                //     number: phoneNumber,
                                //     reference: reference,
                                // })
                                // setModalVisible(true)
    
                            } catch (error) {
                                setModalVisible(false)
                                setIsLoading(false)
                            }
                        }else {
                             setCustomerData({
                                    amount: amount,
                                    number: phoneNumber,
                                    reference: reference,
                                })
                                setFeesInformation({...feesInformation
                                })
                                setModalVisible(true)
                        }
                        

                    }}
                >

                    <Row
                        className='mx-auto'
                        //    gutter={24} 
                        justify="center">

                        <Col style={{ textAlign: 'center' }} md={6}>
                            <div className='ms-3' style={{ backgroungColor: 'red', width: '100', height: '100' }}>

                            </div>
                            <Divider plain>Customer Information</Divider>


                            <Form.Item
                                label="Amount"
                                name="amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input amount!',
                                    },
                                ]} >
                                <Input
                                    type='number'
                                    style={{
                                        backgroundColor: "#f8f9fa",
                                        border: '#f8f9fa',
                                        height: '35px',
                                        width: '100%',
                                        overflow: 'hidden'
                                    }}
                                    onChange={handleAmount}
                                    value={amount}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Phone number"
                                name="number"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input phone number!',
                                    },
                                ]}
                            >
                                <Input
                                    type='number'
                                    style={{
                                        backgroundColor: "#f8f9fa",
                                        border: '#f8f9fa',
                                        height: '35px',
                                        width: '100%',
                                        overflow: 'hidden'
                                    }}
                                    onChange={handlePhonenumber}
                                    value={phoneNumber}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Reference"
                                name="reference"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input reference!',
                                    },
                                ]}
                            >
                                <Input
                                    style={{
                                        backgroundColor: "#f8f9fa",
                                        border: '#f8f9fa',
                                        height: '35px',
                                        width: '100%',
                                        overflow: 'hidden'
                                    }}
                                    onChange={handleReference}
                                    value={reference}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} className='mx-auto'
                        // md={6}
                        >
                            <Divider plain>Payment Option</Divider>
                            <div
                                style={{ textAlign: 'center' }}
                            >


                                {paymentOptions.map((option, index) => {
                                    return (<div
                                        key={index}
                                        style={{ display: 'inline-block', margin: '1.1%' }}
                                    >
                                        <PaymentOptionCard
                                            title={option.title}
                                            image={option.image}
                                            alt={option.alt}
                                            selected={selected.title === option.title}
                                            onClick={() => {
                                                setisDisabled(false)
                                                setSelected(option)
                                            }}
                                        />
                                    </div>)
                                })}

                            </div>

                        </Col>

                        <Form.Item
                            style={{ display: "block", textAlign: 'center' }}
                        >
                            <Button
                                loading={isLoading}
                                htmlType="submit"
                                disabled={isDisabled}
                                // onClick={() => {
                                //     setCustomerData({
                                //         amount: amount,
                                //         number: phoneNumber,
                                //         reference: reference
                                //     })
                                //     setModalVisible(true)
                                // }}
                                type='primary'
                                className='mt-5 me-auto'
                                style={{
                                    backgroundColor: !isDisabled ? "#FCB426" : "#f8f9fa",
                                    border: '#FCB426',
                                    width: '50%',
                                    height: '40px',
                                    color: 'black',
                                    margin: 'auto'
                                }}>
                                Collect
                            </Button>
                        </Form.Item>

                    </Row>

                </Form>
                <Row justify='center'>

                </Row>
                <ConfirmationModal
                    customerData={{
                        amount: amount,
                        number: phoneNumber,
                        reference: reference
                    }}
                    feesInformation={feesInformation}
                    selectedPaymentOption={selected}
                    modalVisible={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    afterClose={props.handleCancel}
                    source={"merchant"}
                />
            </Modal>

        </div>
    )
}