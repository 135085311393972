import { Alert, Button } from "antd";
import React from "react";


export default function AlertPopup() {

    return (
        <div>
            <Alert
                banner={true}
                closable={false}
                // className="alert-popup"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 200,
                    width: '100%',
                    right: 0
                }}
                showIcon
                message="User not onboarded"
                description="Access to SpeedPay is limited. The current logged in user has not yet been onboarded; Kindly contact your Relationship Manager for support"
                type="error"
                // action={
                //     <Button size="large" danger>
                //         Send Request
                //     </Button>
                // }
            />
        </div>
    )
}