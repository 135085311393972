import { utils, write } from "xlsx";
import { saveAs } from "file-saver";

export const exportToCSV = async ({ data, filename, headers, }) => {
    const ws = utils.json_to_sheet(data, { header: headers, });

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }; 

    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' }); 

    const newCSV = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', }); 

    saveAs(newCSV, `${filename}_${Date.now()}.xlsx`);
};