import { Card, Divider, Layout, Modal, Tag } from "antd";
import moment from 'moment'
import React from "react";
import Receipt from "../../result/Receipt";

export default function TransactionDetails(props) {

    const details = props?.transactionInfo

    // console.log(details)

    return (
        <div>
            <Modal
                centered
                open={props.modalVisible}
                onCancel={() => props.onCancel()}
                footer={null}
                title="Details"
                style={{
                    backgroundColor: "#ffffff4d"
                }}
            >
                <Layout
                style={{
                    backgroundColor: "#ECECEC",
                    padding: '2%'
                }}
                >
                    {/* <img
                    style={{
                        zIndex: '10'
                    }}
                    src={require("../../../images/reciepttop.png")} 
                    alt="" 
                    /> */}
                    {/* <Card
                        style={{
                            height: '500px',
                            zIndex: '3',
                            marginTop: '-3px'
                        }}>

                        <h6>Receipt</h6>
                        <p className="text-muted">{details?.id}</p>
                        <p className="text-muted">
                            {moment(details?.createdAt).format("DD MMM yyyy @ HH:mm a ")}
                            </p>
                        <Tag color={details?.status === "pending" ? "geekblue" : details?.status === "failed" ? "red": "green"}
                            style={{
                                position: 'absolute',
                                right: 30,
                                top: 170
                            }}
                        >{details?.status}</Tag>
                        <img
                            src={require('../../../images/momo.jpeg')}
                            // src={props?.selectedPaymentOption?.image}
                            alt=""
                            style={{
                                position: 'absolute',
                                right: 35,
                                top: 20,
                                width: '50px',
                                height: '50px',
                                borderRadius: '100%',
                                objectFit: 'cover'
                            }}
                        />
                        <p className="text-muted">From: {details?.customerNumber}</p>
                        <p className="text-muted">Reference: {details?.reference}</p>
                        <Divider />
                        <h6>Amount</h6>
                        <p style={{
                            textAlign: "right"
                        }}>{details?.amount}</p>
                        <p
                            className="text-muted"
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                left: 0,
                                textAlign: 'center'
                            }}>Served by</p>
                    </Card> */}
                    <Receipt 
                       data={details}
                    />
                    {/* <img 
                    style={{
                        marginTop: '-10px',
                        zIndex: '10'
                    }}
                    src={require("../../../images/recieptbottom.png")} 
                    alt="" /> */}
                </Layout>


            </Modal>
        </div>
    )
}