import Carousel from 'react-bootstrap/Carousel';
import React from "react";

export default function FaqCarousel() {

  const slides = [1, 2, 3, 6]

    return (
        <Carousel variant="dark" style={{height: '100%'}}>
        {
          slides.map(slide => <Carousel.Item>
        <img
          className="d-block w-100"
          src={require(`../../../images/FAQs/Slide${slide}.png`)}
          alt={slide + " slide"}
        />
      </Carousel.Item>)
        }
      
    </Carousel>
    )
}