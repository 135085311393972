import { Modal, Result, Button, Spin, notification } from "antd";
import moment from 'moment'
import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect } from "react";
import { protectedResources } from "../../authConfig";
import { InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import checkStatus from "../../api/checkStatus";
import useSound from 'use-sound'
import ding from '../../sound/ding.mp3'
// import FailedModal from "../../../../src/components/result/FailedModal";
import FailedModal from "./FailedModal";
import checkStatusNoAuth from "../../api/checkStatusNoAuth";

export default function SuccessModal(props) {

    const collectionData = props?.collectionData
    const { instance, accounts, inProgress } = useMsal();
    const [transactionId, setTransactionId] = useState('')
    const [isLoading, setLoading] = useState(false);
    const [remainingTime, setRemainingTime] = useState(5)
    const [failedModalVisible, setFailedModalVisible] = useState(false)
    const [playSound] = useSound(ding)
    const [retryAttempts, setRetryAttempts] = useState(12)

    const openNotification = () => {
        notification['success']({
            message: 'Collection Successful',
            description:
                `Dear merchant, An amount of GHS ${collectionData?.amount} has been successfully collected and recorded`,
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    useEffect(() => {
        if (props.modalVisible) {
            if (retryAttempts > 0) {
                if (remainingTime > 0) {

                    const intervalID = setInterval(() => {
                        setRemainingTime(remainingTime - 1);
                        // console.log(remainingTime);
                    }, 1000);
                    return () => clearTimeout(intervalID);

                } else {
                    if (props?.selectedID === "4") {
                        openNotification()
                        playSound()
                        props.onCancel()
                    } else {
                        if (props?.source === "client") {
                            handleCheckStatusNoAuth()
                        }else {
                            handleCheckStatus()
                        }

                    }

                    // setRemainingTime(3)
                }
            } else {
                setFailedModalVisible(true)
            }

        }



    }, [remainingTime, props.modalVisible])

    const handleCheckStatus = () => {
        setLoading(true)
        instance.acquireTokenSilent({
            scopes: protectedResources.basicAccess.scopes,
            account: accounts[0]
        }).then(response => {
            //get accessToken
            // console.log(response.accessToken)
            checkStatus(response?.accessToken, collectionData?.id)
                .then(result => {
                    setLoading(false)
                    console.log(result);
                    if (result.status) {
                        if (result.data.status === 'pending') {
                            setRemainingTime(3)
                            setRetryAttempts(retryAttempts - 1)
                        } else if (result.data.status === 'successful') {
                            openNotification()
                            playSound()
                            props.onCancel()
                        } else {
                            setFailedModalVisible(true)
                        }

                    } else {
                        setFailedModalVisible(true)
                    }
                }).catch(error => {
                    console.log(error);
                })
            return response.accessToken
        }).catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
                if (accounts[0] && inProgress === InteractionStatus.None) {
                    instance.acquireTokenPopup({
                        scopes: protectedResources.basicAccess.scopes,
                    }).then(response => {
                        // console.log(response.accessToken)
                        checkStatus(response?.accessToken, collectionData?.id)
                            .then(result => {
                                console.log(result);
                                if (result.status) {
                                    if (result.data.status === 'pending') {
                                        setRemainingTime(3)
                                        setRetryAttempts(retryAttempts - 1)
                                    } else if (result.data.status === 'successful') {
                                        openNotification()
                                        playSound()
                                        props.onCancel()
                                    } else {
                                        setFailedModalVisible(true)
                                    }

                                } else {
                                    setFailedModalVisible(true)
                                }
                            }).catch(error => {
                                console.log(error);
                            })
                    }).catch(error => {
                        setLoading(false)
                        setFailedModalVisible(true)
                        console.log(error)
                    });
                }
            }
        })
    }

    const handleCheckStatusNoAuth = () => {

        checkStatusNoAuth(collectionData?.transactionId)
        .then(result => {
            setLoading(false)
            // console.log(result);
            if (result.status) {
                if (result.data.status === 'pending') {
                    setRemainingTime(3)
                    setRetryAttempts(retryAttempts - 1)
                } else if (result.data.status === 'successful') {
                    openNotification()
                    playSound()
                    // props.onCancel()
                    showReceipt()
                } else {
                    setFailedModalVisible(true)
                }

            } else {
                setFailedModalVisible(true)
            }
        }).catch(error => {
            setFailedModalVisible(true)
            console.log(error);
        })

    }

    function showReceipt() {
        window.location.replace(process.env.REACT_APP_X_CLIENT_BASE_URL + "/receipt/" + collectionData?.transactionId)
    }

    return (
        <Modal
            // title='Success'
            centered
            open={props.modalVisible}
            // visible={true}
            onCancel={props.onCancel}
            footer={null}
            closable={false}
            afterClose={props.afterClose}
        >

            <Result
                // status="success"
                icon={<Spin />}
                title="Payment processing"
                subTitle={`Transaction ID: ${collectionData?.id}, payment may take up to 5 minutes to reflect; Please wait as customer confirms transaction`}
                // extra={collectionData?.createdAt}
                extra={
                    <div>
                        {moment(collectionData?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                        {/* <Button
                            // loading={isLoading}
                            type="primary"
                            onClick={() => {
                                // props.onCancel()
                                // handleCheckStatus()
                                // setFailedModalVisible(true);
                                openNotification()
                                playSound()
                            }}
                            style={{
                                display: 'block',
                                backgroundColor: '#FCB426',
                                border: '#FCB426',
                                color: 'black'
                            }}
                            className="mx-auto mt-4">
                            Check status
                        </Button> */}
                    </div>
                }
            />
            <FailedModal
                modalVisible={failedModalVisible}
                afterClose={props?.onCancel}
                onCancel={() => {
                    setFailedModalVisible(false);
                }}
            />
        </Modal>
    )
}