import { Card, Divider } from 'antd';
import { useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import { protectedResources } from '../../authConfig';
import React, { useState, useEffect } from 'react';
import CheckoutForm from './CheckoutForm';
import getMerchantById from '../../api/getMerchantById';

export default function CheckoutCard(props) {

    return (
        <div style={{
            padding: '0 3%',
            position: 'relative'
        }}>
            <Card
                className="mx-auto"
                bordered={false}
                style={{
                    width: "100%",
                    padding: '0 2%',
                    maxWidth: '500px',
                    // height: '80vh'
                }}
            >
                <div style={{
                    display: 'flex',
                    width: '100%',
                }}>
                    <p style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '100%',
                        justifyContent: 'center'
                    }}>
                        <b>
                           {props?.data?.name?.toUpperCase()}
                        </b>

                    </p>
                </div>

                <Divider className='m-0 mb-3' />
                <CheckoutForm
                    data={props?.data}
                    merchantId={props?.merchantId}
                />

            </Card>
        </div >

    )

}



