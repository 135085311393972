import { Button, Card, Tag, Typography, Avatar, Divider, Carousel, Modal } from "antd";
import { AntDesignOutlined } from '@ant-design/icons';
import { PieChart, Pie } from "recharts";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import { protectedResources } from "../../../authConfig";
import AlertPopup from "../../result/AlertPopup";
import React, { useEffect, useState } from "react";

import QrModal from "./QrModal";
import getMerchant from "../../../api/getMerchant";
import FaqCarousel from "./FaqCarousel";

const { Text } = Typography;

export default function Profile() {

    const { instance, accounts, inProgress } = useMsal();
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState({})
    const [isOnboarded, setIsOnboarded] = useState(true)
    const [showFAQ, setShowFAQ] = useState(false)

    useEffect(() => {
        handleMerchantInformation()
    }, [])

    // "antd": "^4.22.8",

    function handleMerchantInformation() {

        setIsLoading(true)
        setData({})
        instance.acquireTokenSilent({
            scopes: protectedResources.basicAccess.scopes,
            account: accounts[0]
        }).then(response => {
            //get accessToken
            // console.log(response.accessToken
            // loadRequests(response.accessToken)
            getMerchant(response.accessToken).then(result => {
                if (result.status) {
                    setIsLoading(false)
                    setData(result?.data)
                    setIsOnboarded(true)
                } else {
                    setIsOnboarded(false)
                }
                // console.log(result);

            }).catch(error => {
                console.log(error)
                setIsLoading(false)
            })
        }).catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
                if (accounts[0] && inProgress === InteractionStatus.None) {
                    instance.acquireTokenPopup({
                        scopes: protectedResources.basicAccess.scopes,
                    }).then(response => {
                        getMerchant(response.accessToken).then(result => {
                            // console.log(result);
                            setIsLoading(false)
                            setData(result?.data)

                        }).catch(error => {
                            console.log(error);
                            setIsLoading(false)

                        })

                    }).catch(error => {
                        setIsLoading(false)
                        console.log(error)

                    });
                }
            }
        })

    }

    const data01 = [
        {
            "name": "Group A",
            "value": 400
        },
        {
            "name": "Group B",
            "value": 300
        },
        {
            "name": "Group C",
            "value": 300
        },
        {
            "name": "Group D",
            "value": 200
        },
        {
            "name": "Group E",
            "value": 278
        },
        {
            "name": "Group F",
            "value": 189
        }
    ];
    const data02 = [
        {
            "name": "Group A",
            "value": 2400
        },
        {
            "name": "Group B",
            "value": 4567
        },
        {
            "name": "Group C",
            "value": 1398
        },
        {
            "name": "Group D",
            "value": 9800
        },
        {
            "name": "Group E",
            "value": 3908
        },
        {
            "name": "Group F",
            "value": 4800
        }
    ];
    const contentStyle = {
        height: '50vh',
        width: '100%',
        objectFit: 'cover'
        // color: '#fff',
        // lineHeight: '160px',
        // textAlign: 'center',
        // background: '#364d79',
        // borderRadius: '10px'
    };

    return (
        <div className="dashboard-home">
            <QrModal
                data={data}
                showModal={showModal}
                onCancel={() => setShowModal(false)}
            />

            <div
                style={{
                    // paddingLeft: '2%'
                }}>
                <h3>Profile</h3>
                <Text type="secondary">View your profile information and user summary.</Text>
                <Button
                    className="extra-buttons"
                    // disabled={isLoading}
                    onClick={() => { setShowModal(true) }}
                    style={{
                        position: 'absolute',
                        backgroundColor: !isLoading ? "#FCB426" : "#f8f9fa",
                        color: 'black',
                        border: 'none',
                        top: 60,
                        right: 100,
                    }}
                >
                    View QR
                </Button>
                <Button
                    className="extra-buttons"
                    onClick={() => { setShowFAQ(true) }}
                    style={{
                        position: 'absolute',
                        backgroundColor: "#FCB426",
                        color: 'black',
                        border: 'none',
                        top: 60,
                        right: 200,
                    }}
                >
                    Show FAQ
                </Button>

                <Button
                    className="dashboard-newbuttons"
                    disabled={isLoading}
                    onClick={() => { setShowModal(true) }}
                    style={{
                        position: 'absolute',
                        backgroundColor: !isLoading ? "#FCB426" : "#f8f9fa",
                        color: 'black',
                        border: 'none',
                        top: 30,
                        right: 30,
                    }}
                >
                    View QR
                </Button>
                <Button
                    className="dashboard-newbuttons"
                    onClick={() => { setShowFAQ(true) }}
                    style={{
                        position: 'absolute',
                        backgroundColor: "#FCB426",
                        color: 'black',
                        border: 'none',
                        top: 30,
                        left: 30,
                    }}
                >
                    Show FAQ
                </Button>
                {!isOnboarded && <AlertPopup />}
            </div>
            <Card
                loading={isLoading}
                className="mt-3"
                style={{
                    height: '20vh',
                    borderRadius: '10px'
                }}>

                <Avatar
                    className="ms-5 me-5 mb-2 mt-3"
                    style={{ float: 'left', backgroundColor: '#f56a00' }}
                    size={{
                        xs: 24,
                        sm: 32,
                        md: 40,
                        lg: 64,
                        xl: 80,
                        xxl: 100,
                    }}
                >
                    {accounts[0]?.name?.charAt(0)}
                </Avatar>
                <div

                    style={{
                        marginLeft: '20px'
                    }}
                >
                    <span><h5 className="mb-3">{data?.merchant?.name}</h5></span>
                    <span >
                        {/* <Tag color={'blue'}>{data?.merchant?.typeOfBusiness}</Tag> */}
                        <Tag color={'red'}>{data?.merchant?.contact}</Tag>
                        <Tag color={'green'}>{data?.merchant?.accountNumber}</Tag>
                        <Tag color={'green'}>{data?.merchant?.ussd || "USSD not assigned"}</Tag>
                    </span>

                    {/* <p className="text-muted mt-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop.</p> */}
                </div>
            </Card>
            {/* <Card 
            className="mt-3" 
            style={{
                    borderRadius: '10px',
                    padding: '3%',
                }}>

                <FaqCarousel />

            </Card> */}
            <Modal
                title={"Frequently Asked Questions"}
                open={showFAQ}
                centered
                footer={null}
                onCancel={() => setShowFAQ(false)}
                destroyOnClose
                width={1000}>
                <FaqCarousel />
            </Modal>
        </div>
    )
}