import { Typography, Row, Col, Card, Tag, Avatar, Input, Button, Segmented, notification, Dropdown, Menu, Pagination, Select, Spin } from "antd";
import { CaretUpOutlined, CaretDownOutlined, EllipsisOutlined, PlusOutlined } from '@ant-design/icons'
import { InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Barcharts from "./charts/Barcharts";
import DoublelineChart from "./charts/DoublelineChart";
import TodayTable from "./charts/TodayTable";
import { useMsal } from "@azure/msal-react";
import { protectedResources } from "../../../authConfig";
import listTransactions from "../../../api/listTransactions";
import useSound from 'use-sound'
import ding from '../../../sound/ding.mp3'
import moment from 'moment'
import './NewDashboard.css'
import { ResponsiveContainer } from "recharts";
import AlertPopup from "../../result/AlertPopup";
import TableView from "./charts/TableView";
import getMerchant from "../../../api/getMerchant";
import TremorBarchart from "./charts/TremorBarchart";
import TremorLinechart from "./charts/TremorLinechart";
import TremorPiechart from "./charts/TremorPiechart";

const { Text } = Typography;
const ColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];

export default function NewDashboard(props) {

    const { instance, accounts, inProgress } = useMsal();
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState(null)
    const [mainTotal, setMainTotal] = useState(0)
    const [selectedSegement, setSelectedSegement] = useState('Today')
    const [percentageChange, setPercentageChange] = useState(0)
    const [refresh, setRefresh] = useState(true)
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(moment().startOf('day').toISOString());
    const [endDate, setEndDate] = useState(moment().endOf('day').toISOString());
    const [playSound] = useSound(ding);
    const [collectedTotal, setCollectedTotal] = useState(0)
    const [isOnboarded, setIsOnboarded] = useState(true)
    const [dropdownValue, setDropdownValue] = useState("all")
    const numEachPage = 50
    const [minValue, setMinValue] = useState(0)
    const [maxValue, setMaxValue] = useState(50)
    const [merchantDetails, setMerchantDetails] = useState(null)
    // useEffect(() => {
    //     getTotal()
    // }, [data])

    function getTotal(data) {
        var total = 0.0
        const oneDay = data?.filter(value => value.status === 'successful')
        oneDay?.forEach(d => {
            total = total + parseFloat(d?.amount)
        })
        // console.log(data);
        setMainTotal(total.toFixed(2))
    }

    function getTransactions(data) {
        const successfulCollections = data?.filter(value => value.status === 'successful')?.map(item => item?.amount)
        ?.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0)
        setCollectedTotal(successfulCollections || 0)
    }

    // const openNotification = () => {
    //     notification['success']({
    //         message: 'Collection Successful',
    //         description:
    //             'Dear agent name, An amount of ... has been successfully collected and recorded',
    //         onClick: () => {
    //             console.log('Notification Clicked!');
    //         },
    //     });
    // };

    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <div>
                            {accounts[0]?.name}
                        </div>
                    ),
                    disabled: true
                },
                {
                    key: '2',
                    label: (
                        <Button
                            onClick={() => handleLogout()}
                            type="text"
                        >
                            SignOut
                        </Button>



                    ),
                }
            ]}
        />
    );

    // function getPercentageChange(data) {
    //     // console.log(data);

    //     var oneDayTotal = 0
    //     var lessOneDayTotal = 0
    //     const oneDay = data?.filter(value => value.key === 'a day ago')
    //     const lessOneDay = data?.filter(value => value.key !== 'a day ago')
    //     oneDay?.forEach(d => {
    //         oneDayTotal = oneDayTotal + parseFloat(d?.amount)
    //     })
    //     lessOneDay?.forEach(d => {
    //         lessOneDayTotal = lessOneDayTotal + parseFloat(d?.amount)
    //     })
    //     console.log(lessOneDayTotal)
    //     const diff = parseInt(((lessOneDayTotal - oneDayTotal) / oneDayTotal) * 100)
    //     setPercentageChange(diff)
    // }

    const handleSegement = (option) => {
        setSelectedSegement(option);
        switch (option) {
            case 'Today':
                setStartDate(moment().startOf('day').toISOString())
                setEndDate(moment().endOf('day').toISOString());
                break;

            case 'This week':
                setStartDate(moment().startOf('week').toISOString())
                setEndDate(moment().endOf('week').toISOString());
                break;

            case 'This month':
                setStartDate(moment().startOf('month').toISOString())
                setEndDate(moment().endOf('month').toISOString());
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        handleUserAssignedMerchant()
    }, [])

    useEffect(() => {
        handleListTransactionsAPI()
        // getTransactions([{amount: '1.00', status: 'successful'}])
        // getTotal([{amount: '1.00', status: 'successful'}])
    }, [refresh, startDate, props?.refresh])

    const handleLogout = () => {
        instance.logoutRedirect().then(response => {
            console.log(response);
        })
    }

    const handleListTransactionsAPI = () => {
        setIsLoading(true)
        setData([])
        instance.acquireTokenSilent({
            scopes: protectedResources.basicAccess.scopes,
            account: accounts[0]
        }).then(response => {
            //get accessToken
            // console.log(response.accessToken
            // loadRequests(response.accessToken)
            listTransactions(response.accessToken, startDate, endDate).then(result => {

                if (result.status) {
                    setIsOnboarded(true)
                    setIsLoading(false)
                    setData(result?.data)
                    getTotal(result?.data)
                    getTransactions(result?.data)

                } else {
                    setIsOnboarded(false)
                }
                // console.log(result);

            }).catch(error => {
                console.log(error)
                setIsLoading(false)
            })
        }).catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
                if (accounts[0] && inProgress === InteractionStatus.None) {
                    instance.acquireTokenPopup({
                        scopes: protectedResources.basicAccess.scopes,
                    }).then(response => {
                        listTransactions(response.accessToken, startDate, endDate).then(result => {
                            // console.log(result);
                            setIsLoading(false)
                            setData(result?.data)
                            getTotal(result?.data)
                            getTransactions(result?.data)
                        }).catch(error => {
                            console.log(error);
                            setIsLoading(false)

                        })

                    }).catch(error => {
                        setIsLoading(false)
                        console.log(error)

                    });
                }
            }
        })
    }

    const handleUserAssignedMerchant = () => {
        instance.acquireTokenSilent({
            scopes: protectedResources.basicAccess.scopes,
            account: accounts[0]
        }).then(response => {
            getMerchant(response.accessToken).then(result => {
                if (result.status) {
                    setMerchantDetails(result?.data)
                }
            }).catch(error => {
                console.log(error)
            })
        }).catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
                if (accounts[0] && inProgress === InteractionStatus.None) {
                    instance.acquireTokenPopup({
                        scopes: protectedResources.basicAccess.scopes,
                    }).then(response => {
                        getMerchant(response.accessToken).then(result => {
                            setMerchantDetails(result?.data)
                        }).catch(error => {
                            console.log(error);
                        })

                    }).catch(error => {
                        console.log(error)
                    });
                }
            }
        })
    }


    function RenderTotalAmounCard() {

        return (
            <div className={"dashboard-card"}>
                <Text type='secondary'>Total Amount</Text>
                <h1 className="mt-4 text-muted">GH₵ {mainTotal}</h1>
                <Text type='secondary'
                    style={{
                        position: 'absolute',
                        bottom: 10,
                        right: 20
                    }}>Since {
                        selectedSegement === "Today" ? 'Yesterday' : selectedSegement === "This week" ? 'Last week' : 'Last month'
                    }</Text>

                <Tag
                    style={{
                        // position: 'absolute',
                        bottom: 20,
                        left: 20
                    }}
                    icon={<CaretUpOutlined />}
                    className="mt-5"
                    color={"#87d068"}>{0.0} % </Tag>
            </div>
        )
    }

    return (
        <div className="dashboard-home">
            <div
                style={{
                    // paddingLeft: '1%'
                }}>
                <h3>Welcome!</h3>
                <Text type="secondary">View report based on current progress.</Text>
                <br />
                <Segmented
                    disabled={!isOnboarded}
                    // defaultValue={selectedSegement}
                    value={selectedSegement}
                    onChange={(value) => handleSegement(value)}
                    className="mt-3"
                    options={['Today', 'This week', 'This month']}
                    style={{ display: 'inline-block' }} />
            </div>

            <div
                className="extra-buttons"
            >
                <Input
                    allowClear
                    placeholder="Search"
                    style={{
                        border: 'solid #fff',
                        position: 'absolute',
                        top: 20,
                        right: 130,
                        width: '15%',
                        // visibility: 'hidden'
                    }}
                />
                {/* <Tag color={"red"} style={{position: 'absolute', top: 140, right: 60}}>{merchantDetails?.merchant?.ussd || "Ussd not assigned"}</Tag> */}
                <Dropdown menu={menu}>
                    <Avatar
                        size="large"
                        style={{
                            backgroundColor: "#f56a00",
                            position: 'absolute',
                            top: 20,
                            right: 70,
                        }}
                    // onClick={() => { console.log('avatar clicked'); }}
                    >
                        {accounts[0]?.name?.charAt(0)}
                    </Avatar>
                </Dropdown>
            </div>

            <div
                className="extra-buttons"
                style={{
                    backgroundColor: '#f0f2f5',
                    position: 'absolute',
                    top: 85,
                    right: 320,
                    height: '40px',
                    borderRadius: '8px',
                    display: 'grid',
                    placeItems: 'center',
                    color: '#000'

                }}>
                <span className="extra-buttons" style={{ margin: '0 15px' }}> <b>USSD: {merchantDetails?.merchant?.ussd || "not assigned"}</b></span>
            </div>

            <Button
                className="extra-buttons"
                size="large"
                onClick={() => { setRefresh(!refresh) }}
                // size="small"
                disabled={!isOnboarded}
                style={{
                    backgroundColor: isOnboarded ? "#FCB426" : "#f8f9fa",
                    border: "none",
                    position: 'absolute',
                    top: 85,
                    right: 220,
                    // width: '5%',
                    // height: '6%',
                    // borderRadius: '8px',
                    // color: "white"
                    color: isOnboarded && 'black'
                }}
            >
                Refresh
            </Button>

            <Button
                className="extra-buttons"
                disabled={!isOnboarded}
                size="large"
                onClick={() => { props.handleCollectClicked() }}
                // size="small"
                style={{
                    backgroundColor: isOnboarded ? "#FCB426" : "#f8f9fa",
                    border: "none",
                    position: 'absolute',
                    top: 85,
                    right: 130,
                    // width: '5%',
                    // height: '6%',
                    // borderRadius: '16px',
                    color: isOnboarded && 'black'
                }}
            >
                Collect
            </Button>

            <Button
                className="extra-buttons"
                disabled={!isOnboarded}
                // size="small"
                size="large"
                // onClick={() => { openNotification(); playSound() }}
                style={{
                    backgroundColor: isOnboarded ? "#fff" : "#f8f9fa",
                    border: "none",
                    position: 'absolute',
                    top: 85,
                    right: 70,
                    // width: '3%',
                    // height: '6%',
                    // borderRadius: 5,
                    color: isOnboarded && 'black'
                }}
            >
                <EllipsisOutlined />
            </Button>
            {/* Refresh and Collect button for small screens*/}


            <Button
                className="dashboard-newbuttons"
                disabled={!isOnboarded}
                size="large"
                style={{
                    backgroundColor: isOnboarded ? "#FCB426" : "#f8f9fa",
                    border: 'none',
                    position: 'absolute',
                    top: 30,
                    right: 20,
                    color: isOnboarded && 'black'

                }}
                onClick={() => { props.handleCollectClicked() }}
            >

                Collect
            </Button>

            <div
                className="dashboard-newbuttons"
                style={{
                    backgroundColor: '#f0f2f5',
                    position: 'absolute',
                    top: 30,
                    left: 20,
                    height: '40px',
                    borderRadius: '8px',
                    display: 'grid',
                    placeItems: 'center',
                    color: '#000'

                }}>
                <span className="dashboard-newbuttons" style={{ margin: '0 10px' }}><b>USSD: {merchantDetails?.merchant?.ussd || "not assigned"}</b></span>
            </div>
            {/* Refresh and Collect button for small screens*/}

            {!isOnboarded && <AlertPopup />}
            {/* Cards start from here */}
            <Row className="mt-3"
                gutter={16}
                style={{
                    height: '75vh'
                }}
            >
                <Col span={14} xl={14} lg={14} sm={24} xs={24}>
                    {/* Transactions collected Card */}
                    <Card
                        // loading={isLoading}
                        style={{
                            // minHeight: '40px',
                            borderRadius: '10px',
                            overflow: 'hidden',
                            height: '38vh',
                            padding: 0
                            // paddingTop: '30px'
                        }}>

                            <div style={{
                            }}>
                                <TremorBarchart data={data} segment={selectedSegement} value={collectedTotal}/>
                            </div>


                    </Card>

                    <Card
                        loading={isLoading}
                        className="dashboard-hide-card"
                        style={{
                            // height: '35vh',
                            borderRadius: '10px',
                            overflow: 'hidden',
                            marginTop: '20px',
                        }}
                    >
                        {RenderTotalAmounCard()}
                    </Card>
                    <Card
                        loading={isLoading}
                        className="dashboard-hide-card"
                        style={{
                            height: '500px',
                            marginTop: '20px',
                            borderRadius: '10px',
                            marginBottom: '20px',
                            textAlign: 'left'
                        }}
                    >
                        <h3 className="mb-2">{selectedSegement}</h3>
                        <Pagination
                            defaultCurrent={1}
                            total={data?.length}
                            style={{ position: 'absolute', bottom: 20, right: 20 }}
                            defaultPageSize={numEachPage}
                            onChange={(value) => {
                                setMinValue((value - 1) * numEachPage)
                                setMaxValue(value * numEachPage)
                            }}
                        />
                        <Select
                            value={dropdownValue}
                            style={{ width: '20%', position: 'absolute', top: 20, right: 20 }}
                            options={[
                                {
                                    label: 'All',
                                    value: 'all',
                                },
                                {
                                    label: 'Successful',
                                    value: 'successful',
                                },
                                {
                                    label: 'Failed',
                                    value: 'failed',
                                },
                                {
                                    label: 'Pending',
                                    value: 'pending',
                                }
                            ]}

                            onChange={(value) => { setDropdownValue(value) }}
                        />

                        <TableView
                            height={"380px"}
                            status={dropdownValue}
                            refresh={refresh}
                            startDate={startDate}
                            endDate={endDate}
                            minValue={minValue}
                            maxValue={maxValue}
                        />
                    </Card>

                    <Row gutter={16}
                        style={{
                            marginTop: '20px'
                        }}
                    >
                        <Col span={12} xl={12} lg={12} md={12} xs={24} sm={24}>
                            <Card
                                // loading={isLoading}
                                style={{
                                    height: '35vh',
                                    borderRadius: '10px',
                                    overflow: 'hidden'
                                }}>
                                {/* <h5 className="text-muted">Transactions</h5> */}

                                {/* <DoublelineChart
                                    data={data}
                                /> */}

                                <TremorLinechart data={data} segment={selectedSegement} />

                            </Card>
                        </Col>
                        <Col span={12} xl={12} lg={12} md={12} xs={24} sm={24}>
                            <Card
                                className="dashboard-newcard"
                                // loading={isLoading}
                                onClick={() => {
                                    // props.handleTransactions()
                                    // navigate("https://www.myumbbank.com")
                                }}
                                // hoverable={true}
                                style={{
                                    height: '35vh',
                                    borderRadius: '10px',
                                    overflow: 'hidden',
                                    padding: '0 !important',
                                    // backgroundColor: "#14b8a6"
                                }}>
                                {/* <Card
                                    bordered={false}
                                    style={{
                                        backgroundColor: 'black',
                                        position: 'absolute',
                                        right: 20,
                                        width: '150px',
                                        borderRadius: '10px',
                                        height: '80%'
                                    }}
                                >
                                    <p style={{ textAlign: 'center', color: 'white' }}>View Transactions in detail</p>
                                    <Card style={{ position: 'absolute', bottom: 30, left: 30, right: 30, overflow: 'hidden', height: '70px' }}>
                                        <img src={require("../../../images/graph-3-2.png")} alt="" width={50} />
                                    </Card>
                                </Card> */}
                                    <TremorPiechart data={data}/>
                                {/* <img src={require("../../../images/report.png")} alt="" style={{ objectFit: "contain" }} /> */}
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col span={10} xl={10} lg={10} xs={0} sm={0}>
                    <Card
                        className="dashboard-newcard"
                        // loading={isLoading}
                        style={{
                            height: '75vh',
                            borderRadius: '10px',
                            overflow: 'scroll'
                        }}
                    >


                        <h3 className="mb-2">{selectedSegement}</h3>
                        <Pagination
                            defaultCurrent={1}
                            total={data?.length}
                            style={{ position: 'absolute', bottom: 20, right: 20 }}
                            defaultPageSize={numEachPage}
                            onChange={(value) => {
                                setMinValue((value - 1) * numEachPage)
                                setMaxValue(value * numEachPage)
                            }}
                        />

                        <Select
                            value={dropdownValue}
                            style={{ width: '20%', position: 'absolute', top: 20, right: 20 }}
                            options={[
                                {
                                    label: 'All',
                                    value: 'all',
                                },
                                {
                                    label: 'Successful',
                                    value: 'successful',
                                },
                                {
                                    label: 'Failed',
                                    value: 'failed',
                                },
                                {
                                    label: 'Pending',
                                    value: 'pending',
                                }
                            ]}

                            onChange={(value) => { setDropdownValue(value) }}
                        />

                         <TableView
                            height={"100%"}
                            status={dropdownValue}
                            refresh={refresh}
                            startDate={startDate}
                            endDate={endDate}
                            minValue={minValue}
                            maxValue={maxValue}
                        />

                        {/* <TodayTable
                            refresh={refresh}
                            startDate={startDate}
                            endDate={endDate}
                        /> */}
                    </Card>
                </Col>

                {/* <Col span={16} 
                style={{
                    padding: '1% 3%'
                }}>
                    <Card 
                    bordered={false}
                    style={{
                        height: '300px',
                    }}
                    >

                    </Card>
                </Col> */}
            </Row>
            <Row gutter={16}>
                {/* <Col span={8} style={{
                paddingLeft: '2%'
            }}>
                    <Card></Card>
            </Col> */}
            </Row>
        </div>
    )
}